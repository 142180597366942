// material-ui
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// project imports
import MainCard from '../../ui-components/extended/card/MainCard'

// assets
import headerBackground from '../../assets/images/landing/bg-header.jpg'

// ============================|| PAGES - PRIVCY POLICY ||============================ //

export default function PrivacyPolicy() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: '100% 600px',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
      }}
    >
      <Container>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid sx={{ mt: { md: 12.5, xs: 2.5 }, mb: { md: 8, xs: 2.5 } }} size={{ sm: 10, md: 7 }}>
            <Grid container spacing={3}>
              <Grid size={12}>
                <Typography
                  variant='h1'
                  color='white'
                  sx={{
                    fontSize: { xs: '1.8125rem', md: '3.5rem' },
                    fontWeight: 900,
                    lineHeight: 1.4,
                    mt: { xs: 10, md: 'auto' },
                  }}
                >
                  Política de Privacidad
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 400, lineHeight: 1.4, my: { xs: 0, md: 'auto' }, mx: { xs: 12.5, md: 'auto' } }}
                  color='white'
                >
                  Última actualización: 2 de abril de 2025
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12}>
            <MainCard elevation={4} border={false} boxShadow sx={{ mb: 3, textAlign: 'justify' }}>
              <Typography mb={3}>
                Esta Política de Privacidad describe cómo EMPREAGENDA S.A.S. (&quot;nosotros&quot;, &quot;nuestro&quot;, &quot;nos&quot;) recopila,
                utiliza y protege la información personal que recabamos a través de nuestra plataforma de reserva de turnos en conformidad con la
                legislación argentina de protección de datos personales especialmente la ley 25.326 (“LPDP”).
              </Typography>

              <Typography variant='h3' mb={1}>
                1. Información que recopilamos
              </Typography>
              <Typography mb={2}>Podemos recopilar y procesar los siguientes tipos de datos personales:</Typography>
              <Typography variant='h5'>a) Datos proporcionados por el usuario:</Typography>
              <ul>
                <li>
                  <Typography>Nombre y apellido</Typography>
                </li>
                <li>
                  <Typography>Dirección de correo electrónico</Typography>
                </li>
                <li>
                  <Typography>Número de teléfono</Typography>
                </li>
              </ul>

              <Typography variant='h5'>b) Datos generados por el uso de la plataforma:</Typography>
              <ul>
                <li>
                  <Typography>Historial de reservas y citas</Typography>
                </li>
                <li>
                  <Typography>Preferencias y configuraciones de usuario</Typography>
                </li>
                <li>
                  <Typography>Datos de interacción con la plataforma (incluyendo fecha, hora y duración de las sesiones)</Typography>
                </li>
              </ul>

              <Typography variant='h5'>c) Datos obtenidos a través de tecnologías de seguimiento:</Typography>
              <ul>
                <li>
                  <Typography>Dirección IP y datos del navegador</Typography>
                </li>
                <li>
                  <Typography>Cookies y tecnologías similares (ver Sección 6)</Typography>
                </li>
                <li>
                  <Typography>Datos de ubicación (si el usuario lo permite)</Typography>
                </li>
              </ul>

              <Typography variant='h5'>d) Exclusión de Datos de Pago:</Typography>
              <ul>
                <li>
                  <Typography>
                    Empreturnos no recopila ni almacena datos de facturación o información de tarjetas de crédito. Todas las transacciones de pago son
                    procesadas por terceros proveedores de servicios de pago, los cuales operan conforme a sus propias políticas de privacidad y
                    seguridad.
                  </Typography>
                </li>
              </ul>

              <Typography variant='h3' mt={3} mb={1}>
                2. Finalidad del tratamiento de los datos
              </Typography>
              <Typography>Utilizamos los datos recopilados para:</Typography>
              <ul>
                <li>
                  <Typography>Facilitar la gestión y reserva de turnos</Typography>
                </li>
                <li>
                  <Typography>Brindar soporte y asistencia técnica</Typography>
                </li>
                <li>
                  <Typography>Mejorar la experiencia del usuario y optimizar la plataforma</Typography>
                </li>
                <li>
                  <Typography>Cumplir con obligaciones legales y regulatorias</Typography>
                </li>
                <li>
                  <Typography>Enviar comunicaciones comerciales y promocionales, con el consentimiento del usuario</Typography>
                </li>
              </ul>

              <Typography variant='h3' mt={3} mb={1}>
                3. Base legal del tratamiento
              </Typography>
              <Typography>El tratamiento de los datos personales se basa en:</Typography>
              <ul>
                <li>
                  <Typography>La ejecución del contrato entre el usuario y Empreturnos</Typography>
                </li>
                <li>
                  <Typography>El cumplimiento de obligaciones legales</Typography>
                </li>
                <li>
                  <Typography>El consentimiento del usuario en ciertos casos (ejemplo: comunicaciones comerciales)</Typography>
                </li>
                <li>
                  <Typography>Nuestro interés legítimo en mejorar los servicios y prevenir fraudes</Typography>
                </li>
              </ul>

              <Typography variant='h3' mt={3} mb={1}>
                4. Compartición de datos
              </Typography>
              <Typography>Podemos compartir la información personal con:</Typography>
              <ul>
                <li>
                  <Typography>Proveedores de servicios que nos asisten en la operación de la plataforma</Typography>
                </li>
                <li>
                  <Typography>Autoridades gubernamentales, si es requerido por ley</Typography>
                </li>
                <li>
                  <Typography>Empresas con las que colaboramos, siempre respetando la normativa aplicable</Typography>
                </li>
              </ul>

              <Typography variant='h3' mt={3} mb={1}>
                5. Transferencias internacionales de datos
              </Typography>
              <Typography>
                Si transferimos datos a servidores fuera de Argentina, garantizamos que se adopten las medidas necesarias para proteger la información
                conforme a la LPDP y normativas internacionales aplicables.
              </Typography>

              <Typography variant='h3' mt={3} mb={1}>
                6. Uso de cookies y tecnologías similares
              </Typography>
              <Typography>
                Utilizamos cookies para mejorar la experiencia del usuario, analizar el tráfico web y personalizar contenido. Puedes gestionar tus
                preferencias desde la configuración de tu navegador o visitar <a href='https://allaboutcookies.org/es'>https://allaboutcookies.org/es</a> para más información.
              </Typography>

              <Typography variant='h3' mt={3} mb={1}>
                7. Seguridad de la información
              </Typography>
              <Typography>
                Aplicamos medidas de seguridad técnicas y organizativas para proteger los datos contra accesos no autorizados, pérdidas o
                modificaciones. Sin embargo, ningún sistema es completamente seguro, por lo que recomendamos a los usuarios tomar precauciones
                adicionales al compartir información en línea.
              </Typography>

              <Typography variant='h3' mt={3} mb={1}>
                8. Derechos del usuario
              </Typography>
              <Typography>Los usuarios tienen derecho a:</Typography>
              <ul>
                <li>
                  <Typography>Acceder a sus datos personales</Typography>
                </li>
                <li>
                  <Typography>Solicitar la rectificación de datos incorrectos</Typography>
                </li>
                <li>
                  <Typography>Pedir la eliminación de sus datos, salvo cuando la conservación sea legalmente necesaria</Typography>
                </li>
                <li>
                  <Typography>Oponerse al uso de sus datos para fines comerciales</Typography>
                </li>
                <li>
                  <Typography>Solicitar la limitación del tratamiento en ciertos casos</Typography>
                </li>
              </ul>

              <Typography variant='h3' mt={3} mb={1}>
                9. Conservación de los datos
              </Typography>
              <Typography>
                Los datos personales se conservarán durante el tiempo necesario para cumplir con las finalidades descritas en esta política y para
                atender responsabilidades legales.
              </Typography>

              <Typography variant='h3' mt={3} mb={1}>
                10. Cambios en la Política de Privacidad
              </Typography>
              <Typography>
                Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Se notificará a los usuarios sobre cambios
                significativos a través de nuestra plataforma o por correo electrónico. Los cambios entrarán en vigor a partir de su publicación en la
                plataforma.
              </Typography>

              <Typography variant='h3' mt={3} mb={1}>
                11. Conformidad
              </Typography>
              <Typography>
                Al utilizar nuestra plataforma, aceptas nuestra Política de Privacidad y autorizas la recopilación, uso y divulgación de tu
                información personal de acuerdo con esta política.
              </Typography>
            </MainCard>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
