import React, { useContext, useState } from 'react'

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import SubscriptionApi from '../../../../api/subscription/SubscriptionApi'
import UserCompanyContext from '../../../../context/usercompany/UserCompanyContext'
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import useAuth from '../../../../context/auth/useAuth'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// apis
const subscriptionApi = new SubscriptionApi()

// ========================|| PAYMENT - CANCEL DIALOG ||======================== //

interface Props extends DialogProps {
  onClose: () => void
}

export default function PaymentMethodCancelDialog({ onClose, ...dialogProps }: Props) {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { userCompany, setUserCompany } = useContext(UserCompanyContext)
  const { setSubscription } = useContext(OptionalSubscriptionContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      subscriptionApi
        .cancel(token, userCompany.id)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  const handleSuccess = () => {
    setSubscription(undefined)
    setUserCompany({ ...userCompany, active: false })

    dispatch(
      setSnackbar({
        message: t('Payment method canceled successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while canceling payment method'),
        severity: 'error',
        open: true,
      }),
    )
  }

  return (
    <Dialog aria-labelledby='delete-payment-dialog' onClose={onClose} PaperProps={{ sx: { p: '12px 0px' } }} {...dialogProps}>
      <DialogTitle id='delete-payment-dialog'>{t('Are you sure you want to delete?')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='delete-payment-dialog-description'>
          {t('By deleting the payment method, the company will become inactive and you will no longer be able to continue using the service. You can always reactivate the subscription by setting up a payment method.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 20px 8px 8px' }}>
        <Button variant='outlined' color='error' onClick={() => onClose()}>
          {t('Not now')}
        </Button>
        <LoadingButton variant='contained' color='error' loading={loading} disableElevation onClick={handleCancel}>
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
