import { useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Alert, Box, Grid2 as Grid, Typography, useMediaQuery } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// project imports
import UserApi from '../../../api/user/UserApi'
import useAuth from '../../../context/auth/useAuth'
import SignUpForm from './SignUpForm'
import Copyright from '../../Landing/Copyright/Copyright'
import ResponsiveCard from '../../../ui-components/extended/card/ResponsiveCard'
import { Country, getDefaultCountry } from '../../../types/Country'
import { ApiError } from '../../../types/ApiError'

// apis
const userApi = new UserApi()

// ========================|| AUTHENTICATION - ACCOUNT FORM ||======================== //

interface FormValues {
  name: string
  email: string
  phone: string
  country: Country
}

const defaultCountry = getDefaultCountry()

export default function SignUp() {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const theme = useTheme()
  const navigate = useNavigate()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  // react-hook-form
  const methods = useForm<FormValues>({
    defaultValues: {
      name: auth!.displayName || '',
      email: auth!.email || '',
      phone: auth!.phoneNumber || '',
      country: defaultCountry,
    },
  })

  // state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()

  const handleSuccess = () => {
    navigate('/users/me/bookings')
  }

  const handleError = (error: ApiError) => {
    setError(error)
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      userApi
        .create(token, {
          name: form.name,
          email: form.email,
          phone: form.phone,
          countryId: form.country.id,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(true))
    })
  }

  return (
    <Box minHeight='100vh' sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Grid container display='flex' flexDirection='column' flexWrap='wrap' justifyContent='flex-end' minHeight='100vh'>
        <Grid maxWidth='none' size={12}>
          <Grid
            container
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='center'
            alignItems='center'
            minHeight='calc(100vh - 68px)'
          >
            <Grid
              sx={{
                [theme.breakpoints.up('xs')]: { margin: '8px' },
                [theme.breakpoints.up('sm')]: { margin: '24px' },
              }}>
              <ResponsiveCard>
                <Grid container spacing={2}>
                  <Grid size={12}>
                    <Typography variant='h2' color='secondary' fontSize={matchesSM ? '1.25rem' : '1.5rem'} gutterBottom>
                      {t('Create Account')}
                    </Typography>
                  </Grid>
                  {error && (
                    <Grid size={12}>
                      <Alert severity='error' sx={{ display: 'flex', alignItems: 'center' }}>
                        {t('Some error has occurred with your request, please try again.')}
                      </Alert>
                    </Grid>
                  )}
                  <Grid size={12}>
                    <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)}>
                      <FormProvider {...methods}>
                        <SignUpForm />
                      </FormProvider>
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        size='large'
                        color='secondary'
                        fullWidth
                        disableElevation
                        loading={loading}
                        sx={{ mt: '24px' }}
                      >
                        {t('Create Account')}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </ResponsiveCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid margin='8px 24px 24px' size={12}>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
