// material-ui
import { Divider } from '@mui/material'

// project imports
import useCompanyId from '../../../hooks/company/useCompanyId'
import MenuGroup from '../../../layout/Menu/MenuGroup'
import MenuItem from '../../../layout/Menu/MenuItem'

// assets
import { IconBriefcase, IconBuildingStore, IconCalendarTime, IconMapPin, IconUser, IconUserShield, IconUserStar, IconHelp } from '@tabler/icons-react'
import MenuCard from './MenuCard/MenuCard'

// ========================|| LAYOUT - MENU ||======================== //

export default function Menu() {
  const companyId = useCompanyId()

  return (
    <>
      <MenuGroup title='Workspace'>
        <MenuItem title='Bookings' url={`/companies/${companyId}/bookings`} icon={IconCalendarTime} />
        <MenuItem title='Clients' url={`/companies/${companyId}/clients`} icon={IconUserStar} />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuGroup title='Resources' caption='Manage your business, branches, professionals, and services.'>
        <MenuItem title='Company' url={`/companies/${companyId}`} icon={IconBuildingStore} />
        <MenuItem title='Branches' url={`/companies/${companyId}/branches`} icon={IconMapPin} />
        <MenuItem title='Professionals' url={`/companies/${companyId}/professionals`} icon={IconUser} />
        <MenuItem title='Services' url={`/companies/${companyId}/services`} icon={IconBriefcase} />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuGroup title='Collaborators' caption='Link users to your business and assign roles to collaborate.'>
        <MenuItem title='Users' url={`/companies/${companyId}/users`} icon={IconUserShield} />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuGroup title='Help' caption='Find all the information to set up and use Empreturnos.'>
        <MenuItem title='Documentation' url='https://empreturnos.gitbook.io/empreturnos' icon={IconHelp} target='_blank' />
      </MenuGroup>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      <MenuCard />
    </>
  )
}
