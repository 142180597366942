import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { AdjustedHour } from '../../types/BusinessHour'

// ========================|| CONTEXT - ADJUSTED HOURS ||======================== //

interface AdjustedHoursContextType {
  adjustedHours: AdjustedHour[]
  setAdjustedHours: Dispatch<SetStateAction<AdjustedHour[]>>
}

const AdjustedHoursContext = createContext<AdjustedHoursContextType>({} as AdjustedHoursContextType)

export default AdjustedHoursContext