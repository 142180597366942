// project imports
import NotificationProvider from '../../../context/notification/NotificationProvider'
import NotificationCard from './NotificationCard/NotificationCard'

// ========================|| SETTINGS - NOTIFICATION ||======================== //

export default function Notification() {
  return (
    <NotificationProvider>
      <NotificationCard />
    </NotificationProvider>
  )
}
