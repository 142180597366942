// third-party
import { useTranslation } from 'react-i18next'

// project imports
import ProfessionalUsersContext from '../../../../context/userprofessional/ProfessionalUsersContext'
import useProfessionalId from '../../../../hooks/professional/useProfessionalId'
import useProfessionalUsers from '../../../../hooks/userprofessional/useProfessionalUsers'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import Loader from '../../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../../ui-components/extended/alert/ApiErrorAlert'
import ProfessionalUserTable from '../ProfessionalUserTable/ProfessionalUserTable'
import ProfessionalUserCreateButton from '../ProfessionalUserCreateButton/ProfessionalUserCreateButton'

// ========================|| PROFESSIONAL - TABS - USERS ||======================== //

export default function ProfessionalUserTab() {
  const professionalId = useProfessionalId()
  const { t } = useTranslation()
  const state = useProfessionalUsers(professionalId)

  if (state.firstLoading) {
    return <Loader />
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <ProfessionalUsersContext.Provider value={state}>
      <SubCard title={t('Users')} content={false} secondary={<ProfessionalUserCreateButton />}>
        <ProfessionalUserTable />
      </SubCard>
    </ProfessionalUsersContext.Provider>
  )
}
