export enum NotificationChannel {
  EMAIL = 'EMAIL',
}

export enum NotificationEvent {
  BOOKING_PENDING = 'BOOKING_PENDING',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_CANCELED = 'BOOKING_CANCELED',
  BOOKING_REMINDER = 'BOOKING_REMINDER',
}

export interface Notification {
  channel: NotificationChannel
  event: NotificationEvent
}
