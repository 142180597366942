// project imports
import { axiosInstance } from '../../axios'
import { Settings } from '../../types/Settings'
import { SettingsUpdateDto } from './dto/SettingsUpdateDto'

// ========================|| API - COMPANY SETTINGS ||======================== //

export default class CompanySettingsApi {
  findById(token: string, companyId: string): Promise<Settings> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/settings`, config)
  }

  update(token: string, companyId: string, settings: SettingsUpdateDto): Promise<Settings> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/companies/${companyId}/settings`, settings, config)
  }
}
