import React, { useContext } from 'react'

// material-ui
import { Grid2 as Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import UserBranchesContext from '../../../../context/userbranch/UserBranchesContext'
import MainTable from '../../../../ui-components/extended/table/MainTable'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import BranchUserRoleChip from '../../../CompanyLayout/Branch/BranchUserRoleChip/BranchUserRoleChip'
import UserBranchSeeButton from '../UserBranchSeeButton/UserBranchSeeButton'
import UserBranchDeleteButton from '../UserBranchDeleteButton/UserBranchDeleteButton'
import CompanyAvatar from '../../../../ui-components/extended/avatar/CompanyAvatar'
import { buildAddress } from '../../../../utils/address'

// ========================|| USER BRANCH - TABLE ||======================== //

export default function UserBranchTable() {
  // hooks
  const { t } = useTranslation()
  const { userBranches, params, setParams } = useContext(UserBranchesContext)

  return (
    <>
      <TableContainer>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('Company')}</TableCell>
              <TableCell>{t('Branch')}</TableCell>
              <TableCell align='center'>{t('Role')}</TableCell>
              <TableCell align='center' width={180}>
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userBranches.content.map(userBranch => (
              <TableRow key={userBranch.id}>
                <TableCell>
                  <Grid container spacing={2} display='flex' alignItems='center' sx={{ flexFlow: 'nowrap' }}>
                    <Grid>
                      <CompanyAvatar active={userBranch.company.active} />
                    </Grid>
                    <Grid>
                      <Typography variant='subtitle1' align='left' whiteSpace='nowrap'>
                        {userBranch.company.displayName}
                      </Typography>
                      <Typography variant='caption' align='left' whiteSpace='nowrap'>
                        @{userBranch.company.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle1'>{userBranch.name}</Typography>
                  <Typography variant='caption'>{buildAddress(userBranch.address)}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <BranchUserRoleChip role={userBranch.role} />
                </TableCell>
                <TableCell align='center'>
                  <UserBranchSeeButton userBranch={userBranch} />
                  <UserBranchDeleteButton userBranch={userBranch} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </TableContainer>
      <MainTablePagination count={userBranches.page.totalElements} pageable={params} setPageable={setParams} />
    </>
  )
}
