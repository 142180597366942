import { useContext, useState } from 'react'

// material-ui
import { Fab, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/AddTwoTone'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import CompanyUserCreateDialog from '../CompanyUserCreateDialog/CompanyUserCreateDialog'
import { isInactive } from '../../../../utils/subscription'

// ========================|| COMPANY USER - CREATE BUTTON ||======================== //

export default function CompanyUserCreateButton() {
  // hooks
  const { t } = useTranslation()
  const { subscription } = useContext(OptionalSubscriptionContext)

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={isInactive(subscription) ? t('An active subscription is required to add users.') : t('Add User')}>
        <span>
          <Fab
            color='primary'
            aria-label='Add administratir'
            sx={{
              width: '32px',
              height: '32px',
              minHeight: '32px',
              boxShadow: 'none',
            }}
            disabled={isInactive(subscription)}
            onClick={handleOpen}
          >
            <AddIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
      <CompanyUserCreateDialog open={open} onClose={handleClose} />
    </>
  )
}
