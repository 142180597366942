export const formatUserName = (value: string) => {
  // Remove invalid characters (only allow letters, spaces, apostrophes and hyphens)
  value = value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ'’ -]/g, '')

  return formatName(value)
}

export const formatName = (value: string) => {
  // Prevent multiple spaces
  value = value.replace(/\s{2,}/g, ' ')

  // Prevent leading spaces
  value = value.replace(/^\s/, '')

  // Prevent apostrophes at the start or end
  value = value.replace(/^['’]+|['’]+$/g, '')

  // Prevent multiple consecutive hyphens
  value = value.replace(/-{2,}/g, '-')

  // Prevent hyphens at the start or end
  value = value.replace(/^-+|-+$/g, '')

  // Prevent multiple consecutive apostrophes
  value = value.replace(/['’]{2,}/g, "'")

  // Prevent periods (.) anywhere in the string
  value = value.replace(/\./g, '')

  return value
}

export const formatNumber = (value: string, maxDigits: number) => {
  // Removes any character that is not a number
  let numericValue = value.replace(/[^0-9]/g, '');

  // Removes leading zeros
  numericValue = numericValue.replace(/^0+(\d)/, '$1');

  // Allow only integers up to 3 digits
  if (numericValue.length > maxDigits) {
    numericValue = numericValue.slice(0, maxDigits);
  }

  return numericValue
}
