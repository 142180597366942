// project imports
import { axiosInstance } from '../../axios'
import { Subscription } from '../../types/Subscription'
import { SubscriptionCreateDto } from './dto/SubscriptionCreateDto'
import { SubscriptionUpdateDto } from './dto/SubscriptionUpdateDto'

// ========================|| API - COMPANY SUBSCRIPTION ||======================== //

export default class SubscriptionApi {
  findByCompanyId(token: string, companyId: string): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/subscriptions`, config)
  }

  create(token: string, companyId: string, subscription: SubscriptionCreateDto): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/subscriptions`, subscription, config)
  }

  update(token: string, companyId: string, subscription: SubscriptionUpdateDto): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/companies/${companyId}/subscriptions`, subscription, config)
  }

  cancel(token: string, companyId: string): Promise<Subscription> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.delete(`/api/v1/companies/${companyId}/subscriptions`, config)
  }
}
