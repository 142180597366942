// material-ui
import { Grid2 as Grid, TextField, useMediaQuery, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import ServiceProfessionalAutocomplete from '../../../../../ui-components/extended/autocomplete/ServiceProfessionalAutocomplete/ServiceProfessionalAutocomplete'
import { ServiceDetails } from '../../../../../types/Service'

// ========================|| BOOKING FORM - SELECT PROFESSIONAL ||======================== //

export default function SelectProfessionalStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const { control, watch, resetField } = useFormContext()

  // state
  const service: ServiceDetails = watch('service')
  const branchId = watch('branch.id')

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Controller
          name='professional'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ServiceProfessionalAutocomplete
              service={service}
              branchId={branchId}
              value={value}
              onChange={(e, professional) => {
                resetField('date')
                resetField('dateTime')
                return onChange(professional)
              }}
              renderInput={params =>
                matchesXS ? (
                  <TextField {...params} placeholder={t('Professional')} error={!!error} helperText={error ? t(error.message as string) : null} />
                ) : (
                  <TextField {...params} label={t('Professional')} error={!!error} helperText={error ? t(error.message as string) : null} />
                )
              }
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
