import { useEffect, useState } from 'react'

// material-ui
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid2 as Grid, InputLabel, OutlinedInput, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import DurationHelperText from '../DurationHelperText/DurationHelperText'
import { formatNumber } from '../../../../../utils/formatter'

// ========================|| SERVICE - FORM ADVANCED ||======================== //

export default function ServiceFormAdvanced() {
  // hooks
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext()

  // state
  const duration = watch('duration')
  const buffer = watch('buffer')
  const [totalDuration, setTotalDuration] = useState(parseInt(duration) + parseInt(buffer))

  useEffect(() => setTotalDuration(parseInt(duration) + parseInt(buffer)), [duration, buffer])

  return (
    <Accordion sx={{ boxShadow: 'none' }} disableGutters elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
        <Typography variant='subtitle1'>{t('Advanced Options')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, mt: 2 }}>
        <Grid container spacing={3}>
          <Grid size={12}>
            <Controller
              name='buffer'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 0, message: 'Buffer time must be positive.' },
                max: { value: 240, message: 'Buffer time must be less than or equal to 4 hours.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel htmlFor='buffer'>{t('Buffer time')}</InputLabel>
                  <OutlinedInput
                    id='buffer'
                    label={t('Buffer time')}
                    value={value}
                    onChange={e => {
                      const value = formatNumber(e.target.value, 3);

                      const totalDuration = parseInt(duration) + parseInt(value)
                      setValue('frequency', totalDuration.toString(), { shouldDirty: true })

                      return onChange(value)
                    }}
                  />
                  <DurationHelperText helperText={error ? t(error.message as string) : t('buffer_helperText')} duration={parseInt(value)} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name='frequency'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 15, message: 'The frequency must be greater than or equal to 15 minutes.' },
                max: { value: totalDuration, message: 'Frequency must be less or equal than duration plus buffer.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel htmlFor='frequency'>{t('Frequency')}</InputLabel>
                  <OutlinedInput
                    id='frequency'
                    label={t('Frequency')}
                    value={value}
                    onChange={e => {
                      const value = formatNumber(e.target.value, 3)
                      return onChange(value)
                    }}
                  />
                  <DurationHelperText helperText={error ? t(error.message as string) : t('frequency_helperText')} duration={parseInt(value)} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
