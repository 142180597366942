import { useContext } from 'react'

// material-ui
import { Box, Grid2 as Grid, ListItemText, Typography } from '@mui/material'

// third-party
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { capitalize } from 'lodash'

// project imports
import CompanyContext from '../../../../../context/company/CompanyContext'
import ClientContext from '../../../../../context/client/ClientContext'
import SubCard from '../../../../../ui-components/extended/card/SubCard'
import CheckoutPricingCard from './CheckoutPricingCard/CheckoutPricingCard'
import RefundPolicyTypography from './RefundPolicyTypography/RefundPolicyTypography'
import { Branch } from '../../../../../types/Branch'
import { ServiceDetails } from '../../../../../types/Service'
import { ServiceProfessional } from '../../../../../types/ServiceProfessional'
import { locale } from '../../../../../utils/locale'
import { isDepositRequired, resolveCurrency, resolvePrice } from '../../../../../utils/serviceProfessional'

// ========================|| BOOKING FORM - CHECKOUT STEP ||======================== //

export default function CheckoutStep() {
  // hooks
  const { company } = useContext(CompanyContext)
  const { client } = useContext(ClientContext)
  const { i18n, t } = useTranslation()
  const { watch } = useFormContext()

  // state
  const branch: Branch = watch('branch')
  const service: ServiceDetails = watch('service')
  const serviceProfessional: ServiceProfessional = watch('professional')
  const dateTime: string = watch('dateTime')

  const price = resolvePrice(service, serviceProfessional)
  const currency = resolveCurrency(service, serviceProfessional)

  const professionalOffset = dayjs().tz(branch.address.timezone).utcOffset()
  const clientOffset = dayjs().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utcOffset()
  const isSameOffset = professionalOffset === clientOffset

  // Obtener el nombre legible del timezone
  const timeZone = new Intl
    .DateTimeFormat(i18n.language, { timeZone: branch.address.timezone, timeZoneName: 'long' })
    .formatToParts(dayjs().tz(branch.address.timezone).toDate())
    .find(part => part.type === 'timeZoneName')?.value || 'Unknown timezone';

  return (
    <SubCard title={t('Booking Summary')}>
      <Grid container spacing={3}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Branch')}
              </Typography>
              <Typography component='p' variant='body1'>
                {branch.address.description}
              </Typography>
            </ListItemText>
          </Grid>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Service')}
              </Typography>
              <Typography component='p' variant='body1'>
                {service.name} ({service.duration} {t('minutes')})
              </Typography>
            </ListItemText>
          </Grid>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Professional')}
              </Typography>
              <Typography component='p' variant='body1'>
                {serviceProfessional.name}
              </Typography>
            </ListItemText>
          </Grid>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Date')}
              </Typography>
              <Typography component='p' variant='body1'>
                {isSameOffset
                  ? capitalize(dayjs(dateTime).locale(locale(i18n.language)).calendar())
                  : capitalize(`${dayjs(dateTime).tz(branch.address.timezone).locale(locale(i18n.language)).format('LLLL')} (${timeZone})`)}
              </Typography>
            </ListItemText>
          </Grid>
          {isDepositRequired(client, service, serviceProfessional) && (
            <Grid size={12}>
              <ListItemText>
                <Typography component='span' variant='subtitle1'>
                  {t('Advance deposit')}
                </Typography>
                <Typography component='p' variant='body1'>
                  {t('An advance deposit is required')}
                </Typography>
              </ListItemText>
            </Grid>
          )}
          {isDepositRequired(client, service, serviceProfessional) && (
            <Grid size={12}>
              <Box>
                <Typography component='span' variant='subtitle1'>
                  {t('Cancelation policy')}
                </Typography>
                {company.settings.refundEnabled ? (
                  <RefundPolicyTypography bookingDate={dateTime} refundAnticipationTime={company.settings.minCancellationNotice} />
                ) : (
                  <Typography component='p' variant='body1'>
                    {t('The deposit will not be refunded if you decide to cancel the booking')}
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
          {/* The price is not defined */}
          {price === null && (
            <Grid size={12}>
              <ListItemText>
                <Typography component='span' variant='subtitle1'>
                  {t('Price')}
                </Typography>
                <Typography component='p' variant='body1'>
                  {t('To be defined')}
                </Typography>
              </ListItemText>
            </Grid>
          )}
          {/* The price is zero */}
          {price === 0 && (
            <Grid size={12}>
              <ListItemText>
                <Typography component='span' variant='subtitle1'>
                  {t('Price')}
                </Typography>
                <Typography component='p' variant='body1'>
                  {t('Free')}
                </Typography>
              </ListItemText>
            </Grid>
          )}
        </Grid>
        {/* Price must be defined and greater than zero */}
        {price !== null && currency !== null && price > 0 ? (
          <Grid size={12}>
            <CheckoutPricingCard currency={currency} subTotal={price} />
          </Grid>
        ) : null}
      </Grid>
    </SubCard>
  )
}
