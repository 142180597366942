import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Notification } from '../../types/Notification'

// ========================|| CONTEXT - NOTIFICATION ||======================== //

interface NotificationContextType {
  notifications: Notification[]
  setNotifications: Dispatch<SetStateAction<Notification[]>>
}

const NotificationContext = createContext<NotificationContextType>({} as NotificationContextType)

export default NotificationContext
