import { ReactNode } from 'react'

// project imports
import BusinessHoursContext from './BusinessHoursContext'
import AdjustedHoursContext from './AdjustedHoursContext'
import useBranchId from '../../hooks/branch/useBranchId'
import useBranchBusinessHours from '../../hooks/businesshour/useBranchBusinessHours'
import useBranchAdjustedHours from '../../hooks/businesshour/useBranchAdjustedHours'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import Loader from '../../ui-components/extended/progress/Loader/Loader'

// ========================|| PROVIDER - BUSINESS HOURS ||======================== //

export default function BranchBusinessHoursProvider({ children }: { children: ReactNode }): JSX.Element {
  const branchId = useBranchId()

  const {
    loading: loadingBusinessHours,
    error: businessHoursError,
    businessHours,
    setBusinessHours
  } = useBranchBusinessHours(branchId)

  const {
    loading: loadingAdjustedHours,
    error: adjustedHoursError,
    adjustedHours,
    setAdjustedHours,
  } = useBranchAdjustedHours(branchId)

  const loading = loadingBusinessHours || loadingAdjustedHours

  if (!branchId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (businessHoursError) {
    return <ApiErrorAlert error={businessHoursError} />
  }

  if (adjustedHoursError) {
    return <ApiErrorAlert error={adjustedHoursError} />
  }

  if (!businessHours || !adjustedHours) {
    return <ApiErrorAlert />
  }

  return (
    <BusinessHoursContext.Provider value={{ businessHours, setBusinessHours }}>
      <AdjustedHoursContext.Provider value={{ adjustedHours, setAdjustedHours }}>
        {children}
      </AdjustedHoursContext.Provider>
    </BusinessHoursContext.Provider>
  )
}
