export enum Weekday {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export interface TimeRange {
  openingTime: string
  closingTime: string
}

export interface BusinessHour {
  weekday: Weekday
  timeRanges: TimeRange[]
}

export interface AdjustedHour {
  date: string
  timeRanges: TimeRange[]
}

export interface Hours {
  businessHours: BusinessHour[]
  adjustedHours: AdjustedHour[]
}

export const DEFAULT_OPENING_TIME = '09:00'

export const DEFAULT_CLOSING_TIME = '17:00'

export const DEFAULT_BUSINESS_HOURS = [
  {
    weekday: Weekday.MONDAY,
    timeRanges: [{ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME }],
  },
  {
    weekday: Weekday.TUESDAY,
    timeRanges: [{ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME }],
  },
  {
    weekday: Weekday.WEDNESDAY,
    timeRanges: [{ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME }],
  },
  {
    weekday: Weekday.THURSDAY,
    timeRanges: [{ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME }],
  },
  {
    weekday: Weekday.FRIDAY,
    timeRanges: [{ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME }],
  },
  {
    weekday: Weekday.SATURDAY,
    timeRanges: [],
  },
  {
    weekday: Weekday.SUNDAY,
    timeRanges: [],
  },
]