// project imports
import { axiosInstance } from '../../axios'
import { AdjustedHour } from '../../types/BusinessHour'
import { AdjustedHoursUpdateDto } from './dto/AdjustedHoursUpdateDto'

// ========================|| API - ADJUSTED HOURS - BRANCH ||======================== //

export default class BranchAdjustedHoursApi {
  findByBranchId(token: string, branchId: string): Promise<AdjustedHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/branches/${branchId}/adjusted-hours`, config)
  }

  update(token: string, branchId: string, adjustedHours: AdjustedHoursUpdateDto): Promise<AdjustedHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.patch(`/api/v1/branches/${branchId}/adjusted-hours`, adjustedHours, config)
  }
}
