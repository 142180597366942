export const matchIsValidCUIT = (cuit: string): boolean => {
  // Remove any non-numeric characters (e.g., hyphens, spaces)
  cuit = cuit.replace(/\D/g, '')

  // The CUIT must be exactly 11 digits long
  if (cuit.length !== 11) {
    return false
  }

  // Multiplication factors for the first 10 digits
  const factors = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]

  let sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cuit[i]) * factors[i]
  }

  // Calculate the remainder of the division by 11
  const remainder = sum % 11

  // Compute the verification digit
  let verificationDigit = 11 - remainder

  // If the result is 11, the verification digit must be 0
  if (verificationDigit === 11) {
    verificationDigit = 0
  }
  // If the result is 10, the CUIT is invalid (no valid digit exists)
  else if (verificationDigit === 10) {
    return false
  }

  // Check if the computed verification digit matches the last digit of the CUIT
  return parseInt(cuit[10]) === verificationDigit
}

export const formatCUIT = (rawValue: string) => {
  if (rawValue.length <= 2) return rawValue
  if (rawValue.length <= 10) return `${rawValue.slice(0, 2)}-${rawValue.slice(2)}`
  return `${rawValue.slice(0, 2)}-${rawValue.slice(2, 10)}-${rawValue.slice(10)}`
}
