// material-ui
import { Box, Container, Grid2 as Grid, Typography } from '@mui/material'

// project imports
import MainCard from '../../ui-components/extended/card/MainCard'

// assets
import headerBackground from '../../assets/images/landing/bg-header.jpg'

// ========================|| TERMS AND CONDITIONS ||======================== //

export default function TermsAndConditions() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: '100% 600px',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
      }}
    >
      <Container>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid sx={{ mt: { md: 12.5, xs: 2.5 }, mb: { md: 8, xs: 2.5 } }} size={{ sm: 10, md: 7 }}>
            <Grid container spacing={3}>
              <Grid size={12}>
                <Typography
                  variant='h1'
                  color='white'
                  sx={{
                    fontSize: { xs: '1.8125rem', md: '3.5rem' },
                    fontWeight: 900,
                    lineHeight: 1.4,
                    mt: { xs: 10, md: 'auto' },
                  }}
                >
                  Términos y Condiciones
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 400, lineHeight: 1.4, my: { xs: 0, md: 'auto' }, mx: { xs: 12.5, md: 'auto' } }}
                  color='white'
                >
                  Última actualización: 2 de abril de 2025
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12}>
            <MainCard elevation={4} border={false} boxShadow sx={{ mb: 3 }}>
              <Grid container spacing={3} textAlign='justify'>
                <Grid size={12}>
                  <Typography>
                    Bienvenido a nuestra plataforma de turnos online &quot;Empreturnos&quot; (en adelante, la &quot;Plataforma&quot;). Estos Términos
                    y Condiciones de Uso (en adelante, los &quot;Términos&quot;) regulan el acceso y uso de la página web y los servicios relacionados
                    ofrecidos por EMPREAGENDA S.A.S. (en adelante, la &quot;Empresa&quot;). Si no está de acuerdo con alguno de ellos, le solicitamos
                    que no acceda, visite ni utilice la Plataforma.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    1. Definiciones
                  </Typography>
                  <Typography mb={1}>1.1. Usuario: Cualquier persona que se registre en la Plataforma.</Typography>
                  <Typography>1.2. Usuario Final: Usuario que utiliza la Plataforma para reservar turnos.</Typography>
                  <Typography>1.3. Usuario Proveedor: Usuario que ofrece turnos a otros Usuarios dentro de la Plataforma.</Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    2. Aceptación de los Términos
                  </Typography>
                  <Typography mb={1}>
                    2.1. Usted manifiesta su aceptación plena y sin reservas de estos Términos al momento de registrarse y/o realizar cualquier acción
                    dentro de la Plataforma que implique su uso.
                  </Typography>
                  <Typography>
                    2.2. Los presentes Términos, junto con las políticas que oportunamente publique la Empresa, constituyen la totalidad de las
                    condiciones de contratación entre la Empresa y los Usuarios, reemplazando cualquier comunicación previa, verbal o escrita.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    3. Servicios
                  </Typography>
                  <Typography mb={1}>
                    3.1. La Plataforma actúa como intermediaria entre los Usuarios Finales que desean reservar turnos y los Usuarios Proveedores que
                    los ofrecen. La Empresa no es responsable de la calidad, cumplimiento o prestación de los servicios ofrecidos por los Usuarios
                    Proveedores.
                  </Typography>
                  <Typography>
                    3.2. La Plataforma ofrece herramientas para que los Usuarios Proveedores gestionen su agenda de turnos y administren su
                    disponibilidad.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    4. Suscripción de los Usuarios Proveedores
                  </Typography>
                  <Typography mb={1}>
                    4.1. Los Usuarios Proveedores pueden acceder a los servicios de la Plataforma mediante una suscripción mensual, cuyo precio varía
                    en función de la cantidad de profesionales registrados.
                  </Typography>
                  <Typography mb={1}>4.2. Los Usuarios Proveedores pueden modificar o cancelar su suscripción en cualquier momento.</Typography>
                  <Typography mb={1}>
                    4.3. La Empresa se reserva el derecho de modificar los precios de las suscripciones, notificándolo previamente a los Usuarios
                    Proveedores.
                  </Typography>
                  <Typography>
                    4.4. Todas las suscripciones son definitivas y no reembolsables, incluso si se cancela o no se utiliza el servicio posteriormente.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    5. Reserva de Turnos y Políticas de Cancelación
                  </Typography>
                  <Typography mb={1}>
                    5.1. Al reservar un turno a través de la Plataforma, el Usuario Final acepta cumplir con los términos y condiciones del Usuario
                    Proveedor correspondiente. La Empresa no se responsabiliza por cancelaciones, modificaciones o incumplimientos por parte de los
                    Usuarios Proveedores.
                  </Typography>
                  <Typography mb={1}>
                    5.2. Cada Usuario Proveedor puede establecer su propia política de cancelación y reprogramación de turnos. Es responsabilidad del
                    Usuario Final informarse sobre dichas políticas antes de realizar una reserva.
                  </Typography>
                  <Typography mb={1}>
                    5.3. Si el Usuario Final decide cancelar una reserva, deberá hacerlo de acuerdo con las condiciones establecidas por el Usuario
                    Proveedor, incluyendo las relativas a reembolsos de señas o pagos.
                  </Typography>
                  <Typography>
                    5.4. En caso de que un Usuario Proveedor cancele un turno que haya sido señado, la seña será reembolsada automáticamente al
                    Usuario Final.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    6. Comunicaciones
                  </Typography>
                  <Typography mb={1}>6.1. La Plataforma enviará correos electrónicos automáticamente a los Usuarios:</Typography>
                  <Typography mb={1}>• Turnos pendientes</Typography>
                  <Typography mb={1}>• Turnos confirmados</Typography>
                  <Typography mb={1}>• Turnos cancelados</Typography>
                  <Typography mb={1}>• Recordatorios de turnos</Typography>
                  <Typography>
                    6.2. Es posible configurar la recepción de estos correos según las preferencias del Usuario. Para ello, se pueden ajustar las
                    opciones en la configuración de la cuenta dentro de la Plataforma.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    7. Registro
                  </Typography>
                  <Typography mb={1}>
                    7.1. Para utilizar ciertas funcionalidades de la Plataforma, es necesario registrarse. El Usuario acepta:
                  </Typography>
                  <Typography mb={1}>• Proporcionar información verídica, completa y actualizada.</Typography>
                  <Typography mb={1}>• No crear cuentas en nombre de terceros sin autorización.</Typography>
                  <Typography mb={1}>
                    • Mantener la confidencialidad de sus credenciales de acceso y notificar de inmediato cualquier uso no autorizado de su cuenta.
                  </Typography>
                  <Typography>
                    7.2. Los Usuarios Proveedores también deben registrar información verídica sobre sus servicios y disponibilidad.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    8. Privacidad
                  </Typography>
                  <Typography>
                    El tratamiento de los datos personales de los Usuarios se realiza de conformidad con nuestra Política de Privacidad y la Ley de
                    Protección de Datos Personales Nº 25.326. Al utilizar la Plataforma, usted consiente dicho tratamiento.
                  </Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    9. Restricciones de Uso
                  </Typography>
                  <Typography mb={1}>El Usuario no podrá:</Typography>
                  <Typography mb={1}>• Realizar ingeniería inversa sobre el software de la Plataforma.</Typography>
                  <Typography mb={1}>• Interferir con las medidas de seguridad.</Typography>
                  <Typography mb={1}>• Utilizar herramientas automatizadas para extraer datos de la Plataforma sin autorización.</Typography>
                  <Typography mb={1}>• Compartir sus credenciales de acceso con terceros.</Typography>
                  <Typography mb={1}>• Usar la Plataforma con fines ilícitos o no autorizados.</Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    10. Propiedad Intelectual
                  </Typography>
                  <Typography mb={1}>
                    10.1. Los derechos de propiedad intelectual sobre la Plataforma, su diseño, logos, marcas y contenido pertenecen únicamente a la
                    Empresa o a terceros que han autorizado su uso.
                  </Typography>
                  <Typography>10.2. El Usuario no adquiere ningún derecho de propiedad intelectual sobre la Plataforma al utilizarla.</Typography>
                </Grid>

                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    11. Limitación de Responsabilidad
                  </Typography>
                  <Typography mb={1}>
                    11.1. La Plataforma se proporciona &quot;TAL CUAL&quot; y &quot;SEGÚN DISPONIBILIDAD&quot;, sin garantías de ningún tipo.
                  </Typography>
                  <Typography mb={1}>
                    11.2. Ningún consejo o información, ya sea oral o escrita, obtenida por usted de nosotros, creará ninguna garantía que no esté
                    expresamente establecida en estos Términos.
                  </Typography>
                  <Typography mb={1}>
                    11.3. La Empresa no será responsable por daños directos, indirectos, incidentales o consecuenciales que surjan del uso de la
                    Plataforma.
                  </Typography>
                  <Typography mb={1}>
                    11.4. La Empresa no garantiza la disponibilidad continua de la Plataforma ni se responsabiliza por fallas técnicas ajenas a su
                    control.
                  </Typography>
                  <Typography mb={1}>
                    11.5. Los Usuarios Proveedores son exclusivamente responsables por los servicios ofrecidos y cualquier reclamo relacionado debe
                    dirigirse a ellos.
                  </Typography>
                  <Typography>
                    11.6. Además de lo anterior, la Empresa no asume ninguna responsabilidad por cualquier error, omisión, interrupción, eliminación, defecto,
                    demora en la operación o transmisión, fallo de la línea de comunicaciones, robo o destrucción o acceso no autorizado a, o
                    alteración de, la Plataforma, aplicaciones o servicios. La Empresa no es responsable de ningún problema o mal funcionamiento técnico de
                    ninguna red telefónica o líneas, sistemas informáticos en línea, servidores o proveedores, equipos informáticos, software, fallos
                    de cualquier correo electrónico debido a problemas técnicos o congestión del tráfico en Internet o en cualquiera de las
                    Plataformas, aplicaciones o servicios o combinación de los mismos, incluyendo cualquier lesión o daño a usted o a cualquier
                    dispositivo móvil o computadora de cualquier persona relacionado o resultante del uso de la Plataforma, aplicaciones o servicios o
                    del uso de los mismos. Bajo ninguna circunstancia la Empresa es responsable de cualquier pérdida o daño, incluyendo lesiones personales
                    o muerte, resultantes del uso de la Plataforma, aplicaciones o servicios, de cualquier contenido publicado en o a través de los
                    servicios, o de la conducta de cualquier Usuario de los servicios, ya sea en línea o fuera de línea. Además, la Empresa no asume ninguna
                    responsabilidad por cualquier dato incorrecto, incluyendo datos personales y/o contenido del Usuario proporcionado por usted o en
                    su nombre y por la presente usted declara y garantiza que es el único responsable de cualquier y todos los datos proporcionados a
                    la Empresa, incluyendo cualquier dato incorrecto y usted asumirá toda la responsabilidad por cualquier consecuencia de la
                    provisión de dichos datos incorrectos a nosotros.
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    12. Modificaciones
                  </Typography>
                  <Typography>
                    La Empresa se reserva el derecho de modificar estos Términos en cualquier momento. Los cambios entrarán en vigencia desde su
                    publicación en la Plataforma. Recomendamos revisar periódicamente los Términos para mantenerse informado sobre las
                    actualizaciones.
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    13. Subsistencia en Caso de Nulidad
                  </Typography>
                  <Typography>
                    Si cualquier disposición de estos Términos y Condiciones es calificada como inválida, ilegal, inaplicable o inejecutable por un
                    tribunal competente, las demás disposiciones del mismo permanecerán vigentes y válidas, y serán aplicables con todos los efectos
                    previstos por las leyes que sean aplicables.
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    14. Ley Aplicable y Jurisdicción
                  </Typography>
                  <Typography>
                    Estos Términos se rigen por la legislación de la República Argentina y cualquier disputa relacionada con estos términos estará
                    sujeta a la jurisdicción exclusiva de los Tribunales Ordinarios Nacionales en lo Comercial con sede en la Ciudad de Buenos Aires.
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant='h3' mb={1}>
                    15. Conformidad
                  </Typography>
                  <Typography>
                    Al registrarse y/o utilizar la Plataforma, usted declara haber leído, comprendido y aceptado los presentes Términos en su
                    totalidad.
                  </Typography>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
