import { ReactNode } from 'react'

// project imports
import DiscountContext from './DiscountContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useDiscounts from '../../hooks/discount/useDiscounts'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - DISCOUNT ||======================== //

export default function DiscountProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { loading, error, discounts, setDiscounts } = useDiscounts(companyId)

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (error && error.status !== 404) {
    return <ApiErrorAlert error={error} />
  }

  return <DiscountContext.Provider value={{ discounts, setDiscounts }}>{children}</DiscountContext.Provider>
}