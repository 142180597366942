import React, { useContext, useState } from 'react'

// material-ui
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import DiscountContext from '../../../../context/discount/DiscountContext'
import DiscountApi from '../../../../api/discount/DiscountApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { ApiError } from '../../../../types/ApiError'
import {
  EXCEPTION_DISCOUNT_CODE_ALREADY_EXISTS,
  EXCEPTION_DISCOUNT_CODE_LIMIT_REACHED,
  EXCEPTION_DISCOUNT_CODE_NOT_FOUND,
} from '../../../../api/exceptions/exceptions'
import { Discount } from '../../../../types/Discount'
import { SubscriptionStatus } from '../../../../types/Subscription'

const discountApi = new DiscountApi()

// ========================|| SUBSCRIPTION - DISCOUNT APPLY BUTTON ||======================== //

export default function SubscriptionDiscountTextField() {
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { subscription } = useContext(SubscriptionContext)
  const { setDiscounts } = useContext(DiscountContext)

  const companyId = useCompanyId()
  const dispatch = useDispatch()

  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setError('')
    setCode(event.target.value)
  }

  const handleSuccess = (discount: Discount) => {
    setCode('')

    setDiscounts(discounts => [...discounts, discount]);

    dispatch(
      setSnackbar({
        message: t('Discount coupon applied successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_DISCOUNT_CODE_NOT_FOUND) {
      setError(EXCEPTION_DISCOUNT_CODE_NOT_FOUND)
      return
    }

    if (error.message === EXCEPTION_DISCOUNT_CODE_ALREADY_EXISTS) {
      setError(EXCEPTION_DISCOUNT_CODE_ALREADY_EXISTS)
      return
    }

    if (error.message === EXCEPTION_DISCOUNT_CODE_LIMIT_REACHED) {
      setError(EXCEPTION_DISCOUNT_CODE_LIMIT_REACHED)
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while applying discount coupon'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleClick = () => {
    if (code.length > 25) {
      setError('Discount coupon is too long.')
      return
    }

    setError('')
    setLoading(true)
    auth!.getIdToken().then(token => {
      discountApi
        .apply(token, companyId, { code })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <TextField
      id='discount-coupon'
      label={t('Discount coupon')}
      value={code}
      disabled={subscription && subscription.status === SubscriptionStatus.PENDING}
      onChange={handleChange}
      error={!!error}
      helperText={error ? t(error) : null}
      fullWidth
      slotProps={{
        input: {
          autoComplete: 'off',
          endAdornment: <LoadingButton loading={loading} disabled={code.trim().length <= 1} onClick={handleClick}>{t('Apply')}</LoadingButton>,
        },
      }}
    />
  )
}