import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import DiscountApi from '../../api/discount/DiscountApi'
import { ApiError } from '../../types/ApiError'
import { Discount } from '../../types/Discount'

// apis
const discountApi = new DiscountApi()

// ========================|| HOOK - DISCOUNTS ||======================== //

export default function useDiscounts(companyId: string) {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [discounts, setDiscounts] = useState<Discount[]>([])

  useEffect(() => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      discountApi
        .findAllByCompanyId(token, companyId)
        .then(data => setDiscounts(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }, [companyId])

  return { loading, error, discounts, setDiscounts }
}
