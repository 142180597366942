// project imports
import { axiosInstance } from '../../axios'
import { CompanyDiscountApplyDto } from './dto/CompanyDiscountApplyDto'
import { Discount } from '../../types/Discount'

// ========================|| API - DISCOUNT ||======================== //

export default class DiscountApi {
  findAllByCompanyId(token: string, companyId: string): Promise<Discount[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/discounts`, config)
  }

  apply(token: string, companyId: string, dto: CompanyDiscountApplyDto): Promise<Discount> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/discounts`, dto, config)
  }
}
