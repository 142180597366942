export enum DiscountStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface Discount {
  code: string
  description: string
  percentage: number
  duration: number
  stackable: boolean
  expiresAt: Date
  status: DiscountStatus
}
