// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import ProfessionalBusinessHoursForm from '../ProfessionalBusinessHoursForm/ProfessionalBusinessHoursForm'

// ========================|| PROFESSIONAL TABS - BUSINESS HOURS CARD ||======================== //

export default function ProfessionalBusinessHoursCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('General availability')} contentSX={{ overflowX: 'scroll' }}>
      <ProfessionalBusinessHoursForm />
    </SubCard>
  )
}
