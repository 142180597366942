import * as React from 'react'
import { useEffect, useState } from 'react'

// material-ui
import { Autocomplete, AutocompleteProps, Box, Typography } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

// project imports
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import ClientApi from '../../../../api/client/CompanyClientApi'
import { Client } from '../../../../types/Client'

// ========================|| UI COMPONENTS - AUTOCOMPLETE - CLIENT ||======================== //

const clientApi = new ClientApi()

interface Props extends Omit<AutocompleteProps<Client, false, false, false>, 'options' | 'loading' | 'getOptionKey' | 'isOptionEqualToValue'> {
}

export default function ClientAutocomplete({ value, ...props }: Props) {
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<readonly Client[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (inputValue && active) {
      fetchOptions(inputValue)
    } else {
      fetchOptions('')
    }
  }, [inputValue])

  useEffect(() => {
    if (value) {
      setOptions([value])
    }
  }, [value])

  const fetchOptions = debounce(async (query: string) => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      clientApi
        .findAll(token, companyId, { search: query, page: 0, size: 5 })
        .then(clients => setOptions(clients.content))
        .finally(() => setLoading(false))
    })
  }, 400)

  const handleInputChange = (event: any, newInputValue: string, reason: string) => {
    if (newInputValue === '' || reason === 'clear') {
      setOptions([])
    }

    if (reason === 'input') {
      setActive(true)
    } else {
      setActive(false)
    }

    setInputValue(newInputValue)
  }

  return (
    <Autocomplete
      {...props}
      fullWidth
      open={open}
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      inputValue={inputValue}
      filterOptions={x => x}
      getOptionKey={option => option.id}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={t('No options')}
      onInputChange={handleInputChange}
      renderOption={(props, client: Client) => (
        <Box component='li' {...props}>
          <Typography>{client.name}</Typography>
        </Box>
      )}
    />
  )
}
