// material-ui
import { Button, FormControl, FormHelperText, Grid2 as Grid, List } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'

// project imports
import AdjustedHourFormControl from '../AdjustedHourFormControl/AdjustedHourFormControl'
import { AdjustedHour, DEFAULT_CLOSING_TIME, DEFAULT_OPENING_TIME } from '../../../../types/BusinessHour'

// ========================|| ADJUSTED HOURS - FORM CONTROL ||======================== //

interface FormValues {
  adjustedHours: AdjustedHour[]
}

interface Props {
  timezone: string
}

export default function AdjustedHoursFormControl({ timezone }: Props) {
  const { t } = useTranslation()
  const { control, clearErrors } = useFormContext<FormValues>()
  const { fields, append, remove } = useFieldArray({ control: control, name: 'adjustedHours' })

  const handleAppend = () => {
    append({ date: dayjs().format('YYYY-MM-DD'), timeRanges: [{ openingTime: DEFAULT_OPENING_TIME, closingTime: DEFAULT_CLOSING_TIME }] })
  }

  const handleDelete = (index: number) => {
    remove(index)
    clearErrors()
  }

  return (
    <Grid container spacing={2}>
      {fields.length > 0 && (
        <Grid size={12}>
          <Controller
            name='adjustedHours'
            control={control}
            render={({ fieldState: { error } }) => (
              <FormControl variant='standard'>
                <List disablePadding>
                  {fields.map((field, adjustedHourIndex) => (
                    <AdjustedHourFormControl
                      key={field.id}
                      adjustedHourIndex={adjustedHourIndex}
                      timezone={timezone}
                      onDelete={() => handleDelete(adjustedHourIndex)}
                    />
                  ))}
                </List>
                {error ? <FormHelperText error>{t(error.message as string)}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>
      )}
      <Grid size={12}>
        <Button onClick={handleAppend}>{t('Change availability on a date')}</Button>
      </Grid>
    </Grid>
  )
}
