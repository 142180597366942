// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// ========================|| HOOK - USER BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const settingsUrl = { title: t('Settings'), url: '/users/me' }

  const profile = { title: t('Profile') }
  const notifications = { title: t('Notifications') }

  switch (pathname.split('/').at(-1)) {
    case 'me': {
      return [settingsUrl, profile]
    }
    case 'notifications': {
      return [settingsUrl, notifications]
    }
    default: {
      return [settingsUrl]
    }
  }
}

export default useBreadcrumbs
