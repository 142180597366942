// material-ui
import { Autocomplete, Box, FormControl, Grid2 as Grid, TextField } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import { countries } from '../../../../types/Country'
import { formatName } from '../../../../utils/formatter'

// ========================|| USER COMPANY - FORM ||======================== //

export default function UserCompanyForm() {
  // hooks
  const { t } = useTranslation()
  const { control } = useFormContext()

  const buildURL = (url: string) => {
    return `${process.env.REACT_APP_HOST}/${url.toLowerCase()}`
  }

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='displayName'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id='displayName'
              label={t('Name')}
              fullWidth
              value={value}
              onChange={e => {
                const value = formatName(e.target.value)
                return onChange(value)
              }}
              error={!!error}
              helperText={error ? t(error.message as string) : null}
              slotProps={{
                input: {
                  autoComplete: 'off'
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            pattern: {
              value: /^[a-z0-9]+$/,
              message: 'ID can only contain lowercase characters or numbers.',
            },
            minLength: { value: 2, message: 'ID is too short.' },
            maxLength: { value: 100, message: 'ID is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('ID')}
                value={value}
                onChange={event => onChange(event.target.value.toLowerCase())}
                error={!!error}
                helperText={error ? t(error.message as string) : value ? buildURL(value) : t('It will be the identifier of the booking page URL.')}
                slotProps={{
                  input: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='country'
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Autocomplete
                id='country'
                options={countries}
                value={value || null}
                onChange={(e, option) => onChange(option)}
                getOptionLabel={option => option.name}
                disableClearable
                isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading='lazy'
                      width='20'
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=''
                    />
                    {option.name} ({option.code})
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('Country')}
                    slotProps={{
                      htmlInput: {
                        ...params.inputProps,
                        autoComplete: 'off',
                      },
                    }}
                    error={!!error}
                    helperText={error ? t(error.message as string) : t('You will not be able to update this field later.')}
                  />
                )}
              />
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
