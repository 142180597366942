import { createContext, Dispatch, SetStateAction } from 'react'

// project imports
import { Discount } from '../../types/Discount'

// ========================|| CONTEXT - DISCOUNT ||======================== //

interface DiscountContextType {
  discounts: Discount[]
  setDiscounts: Dispatch<SetStateAction<Discount[]>>
}

const DiscountContext = createContext<DiscountContextType>({} as DiscountContextType)

export default DiscountContext
