// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DialogProps } from '@mui/material/Dialog'

// third-party
import { Trans, useTranslation } from 'react-i18next'

// ========================|| SUBSCRIPTION - FREE TRIAL CANCEL DIALOG ||======================== //

interface Props extends DialogProps {
  freeTrialRemainingDays: number
  loading: boolean
  onConfirm: () => void
  onClose: () => void
}

export default function SubscriptionFreeTrialCancelDialog({ freeTrialRemainingDays, loading, onConfirm, onClose, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog {...props} aria-labelledby='subscription-trial-cancel-dialog' onClose={onClose}>
      <DialogTitle id='subscription-trial-cancel-dialog'>{t('You will be redirected')}</DialogTitle>
      <DialogContent>
        {freeTrialRemainingDays > 0 && (
          <Typography mb={2}>
            <Trans i18nKey='subscription_trial_cancel' count={freeTrialRemainingDays}>
              You still have <strong title={`${freeTrialRemainingDays}`}>days</strong> left in your free trial. If you subscribe now, your free trial
              will end immediately, and your paid subscription will start.
            </Trans>
          </Typography>
        )}
        <Typography>{t('Click continue to be re-directed to Mercado Pago website to complete your purchase securely.')}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: '0 20px 20px 20px' }}>
        <Grid size={12} container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button variant='outlined' fullWidth onClick={onClose}>
              {t('Cancel')}
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <LoadingButton variant='contained' disableElevation fullWidth loading={loading} onClick={onConfirm}>
              {t('Continue')}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
