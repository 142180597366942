// project imports
import { axiosInstance } from '../../axios'
import { Notification } from '../../types/Notification'

// ========================|| API - NOTIFICATION ||======================== //

export default class NotificationApi {
  findAllByUserId(token: string): Promise<Notification[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get('/api/v1/users/me/notifications', config)
  }

  update(token: string, notifications: Notification[]): Promise<Notification[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put('/api/v1/users/me/notifications', notifications, config)
  }
}
