import { Country } from './Country'

export enum FrequencyType {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export interface Plan {
  price: number
  additionalPrice: number
  taxes: number
  currency: string
  frequency: number
  frequencyType: FrequencyType
  country: Country
}