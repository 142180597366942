import { Address } from '../types/Branch'

export const buildAddress = (address: Address) => {
  let addressStr = ''

  if (address.city) addressStr += `${address.city}`
  if (address.state) addressStr += `${addressStr && address.city ? ', ' : ''}${address.state}`
  if (address.country) addressStr += `${addressStr && (address.city || address.state) ? ', ' : ''}${address.country}`

  return addressStr
}
