// third-party
import { Outlet } from 'react-router-dom'

// project imports
import MainCard from '../../../ui-components/extended/card/MainCard'
import Breadcrumbs from '../../../ui-components/extended/breadcrumbs/Breadcrumbs'
import useBreadcrumbs from '../../../hooks/user/useBreadcrumbs'
import UserTabs from './UserTabs'

// ========================|| USER ||======================== //

export default function User() {
  const items = useBreadcrumbs()

  return (
    <MainCard title={<Breadcrumbs items={items} />} border={false}>
      <UserTabs />
      <Outlet />
    </MainCard>
  )
}
