import React, { useContext, useState } from 'react'

// material-ui
import { Autocomplete, Box, Checkbox, Divider, FormControl, Grid2 as Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// third-party
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import ProfessionalContext from '../../../../context/professional/ProfessionalContext'
import ProfessionalApi from '../../../../api/professional/ProfessionalApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useBranches from '../../../../hooks/branch/useBranches'
import useServices from '../../../../hooks/service/useServices'
import SubCard from '../../../../ui-components/extended/card/SubCard'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Branch } from '../../../../types/Branch'
import { Professional } from '../../../../types/Professional'
import { ApiError } from '../../../../types/ApiError'
import { ServiceDetails } from '../../../../types/Service'
import { EXCEPTION_PROFESSIONAL_NAME_ALREADY_EXIST } from '../../../../api/exceptions/exceptions'
import { formatName } from '../../../../utils/formatter'

// apis
const professionalApi = new ProfessionalApi()

// ========================|| PROFESSIONAL - TABS - PROFESSIONAL ||======================== //

interface FormValues {
  name: string
  branch: Branch
  services: ServiceDetails[]
}

export default function ProfessionalTab() {
  // hooks
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { professional, setProfessional } = useContext(ProfessionalContext)
  const { loading: loadingBranches, branches } = useBranches(companyId)
  const { loading: loadingServices, services } = useServices(companyId)
  const dispatch = useDispatch()

  // react-hook-form
  const { handleSubmit, control, reset, formState, setError } = useForm<FormValues>({
    defaultValues: {
      name: professional.name,
      branch: professional.branch,
      services: professional.services,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (professional: Professional) => {
    setProfessional(professional)

    reset({
      name: professional.name,
      branch: professional.branch,
      services: professional.services,
    })

    dispatch(
      setSnackbar({
        message: t('Professional updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_PROFESSIONAL_NAME_ALREADY_EXIST) {
      setError('name', { message: EXCEPTION_PROFESSIONAL_NAME_ALREADY_EXIST })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while editing professional'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      professionalApi
        .update(token, professional.id, {
          name: form.name,
          branchId: form.branch.id,
          serviceIds: form.services.map(service => service.id),
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <SubCard title={t('Details')}>
      <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'This field is required.',
                minLength: { value: 2, message: 'Name is too short.' },
                maxLength: { value: 100, message: 'Name is too long.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <TextField
                    id='name'
                    label={t('Name')}
                    value={value}
                    onChange={e => {
                      const value = formatName(e.target.value)
                      return onChange(value)
                    }}
                    error={!!error}
                    helperText={error ? t(error.message as string) : null}
                    slotProps={{
                      input: {
                        autoComplete: 'off'
                      }
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='branch'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    id='branch'
                    options={branches}
                    value={value || null}
                    loading={loadingBranches}
                    onChange={(e, branch) => onChange(branch)}
                    disableClearable
                    isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                    getOptionLabel={branch => branch.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('Branch')}
                        error={!!error}
                        helperText={error ? t(error.message as string) : null}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name='services'
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    id='services'
                    options={services}
                    value={value || null}
                    loading={loadingServices}
                    multiple
                    limitTags={5}
                    disableCloseOnSelect
                    onChange={(e, service) => onChange(service)}
                    isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
                    getOptionLabel={service => service.name}
                    renderOption={(props, service, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          id={service.name}
                          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                          checkedIcon={<CheckBoxIcon fontSize='small' />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {service.name}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label={t('Services')} />}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
              {t('Save changes')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </SubCard>
  )
}
