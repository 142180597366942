// project imports
import { axiosInstance } from '../../axios'
import { AdjustedHour } from '../../types/BusinessHour'
import { AdjustedHoursUpdateDto } from './dto/AdjustedHoursUpdateDto'

// ========================|| API - ADJUSTED HOURS - PROFESSIONAL ||======================== //

export default class ProfessionalAdjustedHoursApi {
  findByProfessionalId(token: string, professionalId: string): Promise<AdjustedHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/professionals/${professionalId}/adjusted-hours`, config)
  }

  update(token: string, professionalId: string, adjustedHours: AdjustedHoursUpdateDto): Promise<AdjustedHour[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.put(`/api/v1/professionals/${professionalId}/adjusted-hours`, adjustedHours, config)
  }
}
