// material-ui
import { Typography } from '@mui/material'

// third-party
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

// ========================|| CHECKOUT STEP - REFUND POLICY ||======================== //

interface Props {
  bookingDate: string
  refundAnticipationTime: number
}

export default function RefundPolicyTypography({ bookingDate, refundAnticipationTime }: Props) {
  const { t } = useTranslation()

  const diff = dayjs(bookingDate)
    .subtract(refundAnticipationTime, 'hours')
    .diff(dayjs(), 'hours')

  const refundPolicy = () => {
    if (diff < 0) {
      return t('The deposit will not be refunded if you decide to cancel the booking')
    }

    if (refundAnticipationTime === 0) {
      return t('The deposit will be refunded if you cancel at any time')
    }

    if (refundAnticipationTime === 1) {
      return t('The deposit will be refunded if you cancel up to 1 hour before the booking')
    }

    if (refundAnticipationTime < 24) {
      return t('The deposit will be refunded if you cancel up to {{anticipation}} hours before the booking', { anticipation: refundAnticipationTime })
    }

    if (refundAnticipationTime === 24) {
      return t('The deposit will be refunded if you cancel up to 1 day before the booking')
    }

    const days = Math.floor(refundAnticipationTime / 24);
    const hours = refundAnticipationTime % 24

    const dayLabel = days === 1 ? t('day') : t('days')
    const hourLabel = hours === 1 ? t('hour') : t('hours')

    if (hours === 0) {
      return t('deposit_refund_days_only', { days, dayLabel });
    }

    return t('deposit_refund_message', { days, hours, dayLabel, hourLabel });
  }

  return (
    <Typography component='p' variant='body1'>
      {refundPolicy()}
    </Typography>
  )
}