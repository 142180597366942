// ========================|| UTILS - TIMEZONE ||======================== //

export interface Timezone {
  id: string
  label: string
  country: string
}

export const findTimezoneLabel = (id: string) => timezone.find(timezone => timezone.id === id)?.label || ''

const timezone: Timezone[] = [
  {
    id: 'America/Argentina/Buenos_Aires',
    label: '(GMT-03:00) Buenos Aires, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Buenos_Aires',
    label: '(GMT-03:00) Buenos Aires, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Catamarca',
    label: '(GMT-03:00) Catamarca, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Cordoba',
    label: '(GMT-03:00) Cordoba, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Jujuy',
    label: '(GMT-03:00) Jujuy, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/La_Rioja',
    label: '(GMT-03:00) La Rioja, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Mendoza',
    label: '(GMT-03:00) Mendoza, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Rio_Gallegos',
    label: '(GMT-03:00) Rio Gallegos, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Salta',
    label: '(GMT-03:00) Salta, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/San_Juan',
    label: '(GMT-03:00) San Juan, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/San_Luis',
    label: '(GMT-03:00) San Luis, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Tucuman',
    label: '(GMT-03:00) Tucuman, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Ushuaia',
    label: '(GMT-03:00) Ushuaia, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/La_Pampa',
    label: '(GMT-03:00) La Pampa, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Chaco',
    label: '(GMT-03:00) Chaco, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Formosa',
    label: '(GMT-03:00) Formosa, Argentina',
    country: 'AR',
  },
  {
    id: 'America/Argentina/Misiones',
    label: '(GMT-03:00) Misiones, Argentina',
    country: 'AR',
  },
]
