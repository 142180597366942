// material-ui
import { TextField } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import useCompanyId from '../../../../../hooks/company/useCompanyId'
import ServiceAutocomplete from '../../../../../ui-components/extended/autocomplete/ServiceAutocomplete/ServiceAutocomplete'
import { BookingCreateForm } from '../../../../../types/Booking'

// ========================|| BOOKING - FORM - SELECT SERVICE STEP ||======================== //

export default function SelectServiceStep() {
  const companyId = useCompanyId()
  const { t } = useTranslation()
  const { control, resetField } = useFormContext<BookingCreateForm>()

  return (
    <Controller
      name='service'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ServiceAutocomplete
          companyId={companyId}
          value={value}
          onChange={(e, service) => {
            resetField('professional')
            resetField('date')
            resetField('dateTime')
            return onChange(service)
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={t('Service')}
              error={!!error}
              helperText={error ? t(error.message as string) : null}
            />
          )}
        />
      )}
    />
  )
}