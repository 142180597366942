import { ReactNode } from 'react'

// project imports
import BusinessHoursContext from './BusinessHoursContext'
import AdjustedHoursContext from './AdjustedHoursContext'
import useProfessionalId from '../../hooks/professional/useProfessionalId'
import useProfessionalBusinessHours from '../../hooks/businesshour/useProfessionalBusinessHours'
import useProfessionalAdjustedHours from '../../hooks/businesshour/useProfessionalAdjustedHours'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import Loader from '../../ui-components/extended/progress/Loader/Loader'

// ========================|| PROVIDER - BUSINESS HOURS ||======================== //

export default function ProfessionalBusinessHoursProvider({ children }: { children: ReactNode }): JSX.Element {
  const professionalId = useProfessionalId()

  const {
    loading: loadingBusinessHours,
    error: businessHoursError,
    businessHours,
    setBusinessHours
  } = useProfessionalBusinessHours(professionalId)

  const {
    loading: loadingAdjustedHours,
    error: adjustedHoursError,
    adjustedHours,
    setAdjustedHours,
  } = useProfessionalAdjustedHours(professionalId)

  const loading = loadingBusinessHours || loadingAdjustedHours

  if (!professionalId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (businessHoursError) {
    return <ApiErrorAlert error={businessHoursError} />
  }

  if (adjustedHoursError) {
    return <ApiErrorAlert error={adjustedHoursError} />
  }

  if (!businessHours || !adjustedHours) {
    return <ApiErrorAlert />
  }

  return (
    <BusinessHoursContext.Provider value={{ businessHours, setBusinessHours }}>
      <AdjustedHoursContext.Provider value={{ adjustedHours, setAdjustedHours }}>
        {children}
      </AdjustedHoursContext.Provider>
    </BusinessHoursContext.Provider>
  )
}
