// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import BranchBusinessHoursCard from '../BranchBusinessHoursCard/BranchBusinessHoursCard'
import BranchAdjustedHoursCard from '../BranchAdjustedHoursCard/BranchAdjustedHoursCard'
import BranchBusinessHoursProvider from '../../../../context/businesshour/BranchBusinessHoursProvider'

// ========================|| BRANCH TABS - BUSINESS HOURS ||======================== //

export default function BranchBusinessHoursTab() {
  return (
    <BranchBusinessHoursProvider>
      <Grid container spacing={3}>
        <Grid size={12}>
          <BranchBusinessHoursCard />
        </Grid>
        <Grid size={12}>
          <BranchAdjustedHoursCard />
        </Grid>
      </Grid>
    </BranchBusinessHoursProvider>
  )
}
