// project imports
import { Plan } from '../types/Plan'
import { Subscription, SubscriptionStatus } from '../types/Subscription'
import { Discount, DiscountStatus } from '../types/Discount'

// ========================|| UTILS - SUBSCRIPTION ||======================== //

export const getSubTotal = (plan: Plan, professionals: number): number => {
  return Math.round(plan.price + plan.additionalPrice * (professionals - 1))
}

export const getTotal = (plan: Plan, professionals: number, discounts: Discount[]): number => {
  const subTotal = getSubTotal(plan, professionals)
  const discountPercentage = getDiscountPercentage(discounts)
  const discountAmount = getDiscountAmount(subTotal, discountPercentage)
  return Math.round(subTotal - discountAmount)
}

export const getDiscountPercentage = (discounts: Discount[]): number => {
  return discounts
    .filter(discount => discount.status === DiscountStatus.ACTIVE)
    .map(discount => discount.percentage)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export const getDiscountAmount = (subTotal: number, discountPercentage: number): number => {
  return Math.round(subTotal * discountPercentage)
}

export const isActive = (subscription: Subscription | undefined): boolean => {
  // There is no active subscription
  if (!subscription) return false;

  switch (subscription.status) {
    case SubscriptionStatus.ACTIVE: return true
    case SubscriptionStatus.TRIALING: return true
    case SubscriptionStatus.CANCELING: return true
    case SubscriptionStatus.PENDING: return false
    case SubscriptionStatus.PAUSED: return false
    case SubscriptionStatus.CANCELED: return false
    case SubscriptionStatus.EXPIRED: return false
  }
}

export const isInactive = (subscription: Subscription | undefined): boolean => {
  return !isActive(subscription)
}