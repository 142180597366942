import React, { ChangeEvent, useContext, useState } from 'react'

// material-ui
import { Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid2 as Grid, Switch, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import useAuth from '../../../../context/auth/useAuth'
import NotificationContext from '../../../../context/notification/NotificationContext'
import NotificationApi from '../../../../api/notification/NotificationApi'
import { Notification, NotificationChannel, NotificationEvent } from '../../../../types/Notification'
import { setSnackbar } from '../../../../store/snackbar/reducer'

const notificationApi = new NotificationApi()

// ========================|| USER - NOTIFICATIONS ||======================== //

interface FormValues {
  notifications: Notification[]
}

const arraysContainSameElements = (arr1: Notification[], arr2: Notification[]) => {
  if (arr1.length !== arr2.length) return false

  const set1 = new Set(arr1.map(item => JSON.stringify(item)))
  const set2 = new Set(arr2.map(item => JSON.stringify(item)))

  return Array.from(set1).every(item => set2.has(item))
}

export default function NotificationForm() {
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { notifications, setNotifications } = useContext(NotificationContext)
  const dispatch = useDispatch()

  const { control, getValues, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      notifications: notifications,
    },
  })

  const { fields, append, remove } = useFieldArray({ control, name: 'notifications' })

  const [loading, setLoading] = useState(false)

  const isChecked = (event: NotificationEvent): boolean => {
    return fields.some(notification => notification.event === event)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventName = event.target.name as NotificationEvent

    if (isChecked(eventName)) {
      const index = fields.findIndex(notification => notification.event === eventName)
      remove(index)
    } else {
      append({ channel: NotificationChannel.EMAIL, event: eventName })
    }
  }

  const isDirty = () => {
    const { notifications: values } = getValues()
    return !arraysContainSameElements(notifications, values)
  }

  const handleSuccess = (notifications: Notification[]) => {
    setNotifications(notifications)
    reset({ notifications })

    dispatch(
      setSnackbar({
        message: t('Notifications updated successfully') as string,
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating notifications'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      notificationApi
        .update(token, form.notifications)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <FormControl component='fieldset' variant='standard'>
            <FormLabel component='legend'>
              <Typography variant='subtitle1' mb={1}>
                {t('When to email?')}
              </Typography>
            </FormLabel>
            <FormGroup onChange={handleChange}>
              <FormControlLabel
                name={NotificationEvent.BOOKING_PENDING}
                checked={isChecked(NotificationEvent.BOOKING_PENDING)}
                control={<Switch name='BOOKING_PENDING' />}
                label={t('Pending bookings')}
              />
              <FormControlLabel
                name={NotificationEvent.BOOKING_CONFIRMED}
                checked={isChecked(NotificationEvent.BOOKING_CONFIRMED)}
                control={<Switch name='BOOKING_CONFIRMED' />}
                label={t('Confirmed bookings')}
              />
              <FormControlLabel
                name={NotificationEvent.BOOKING_CANCELED}
                checked={isChecked(NotificationEvent.BOOKING_CANCELED)}
                control={<Switch name='BOOKING_CANCELED' />}
                label={t('Canceled bookings')}
              />
              <FormControlLabel
                name={NotificationEvent.BOOKING_REMINDER}
                checked={isChecked(NotificationEvent.BOOKING_REMINDER)}
                control={<Switch name='BOOKING_REMINDER' />}
                label={t('Booking reminders')}
              />
            </FormGroup>
            <FormHelperText>{t('This setting applies to both your personal bookings and the bookings of the companies, branches, and professionals you manage.')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton variant='contained' type='submit' disabled={!isDirty()} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}
