import { ReactNode, useContext } from 'react'

// project imports
import BillingContext from './BillingContext'
import PaymentContext from '../payment/PaymentContext'
import UserCompanyContext from '../usercompany/UserCompanyContext'
import useBilling from '../../hooks/billing/useBilling'
import usePayments from '../../hooks/payment/usePayments'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - PAYMENTS ||======================== //

export default function BillingPaymentsProvider({ children }: { children: ReactNode }): JSX.Element {
  const { userCompany } = useContext(UserCompanyContext)
  const { loading: loadingBilling, error: errorBilling, billing, setBilling } = useBilling(userCompany.id)
  const { loading: loadingPayments, error: errorPayments, payments, pageable, setPageable } = usePayments(userCompany.id)

  const loading = loadingBilling || loadingPayments

  if (loading) {
    return <Loader />
  }

  if (errorBilling && errorBilling.status !== 404) {
    return <ApiErrorAlert error={errorBilling} />
  }

  if (errorPayments) {
    return <ApiErrorAlert error={errorPayments} />
  }

  return (
    <BillingContext.Provider value={{ billing, setBilling }}>
      <PaymentContext.Provider value={{ payments, pageable, setPageable }}>
        {children}
      </PaymentContext.Provider>
    </BillingContext.Provider>
  )
}
