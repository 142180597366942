export interface Country {
  id: number
  code: string
  name: string
}

export const countries: readonly Country[] = [
  {
    id: 1,
    code: 'AR',
    name: 'Argentina'
  }
]

export const getDefaultCountry = (): Country => {
  return {
    id: 1,
    code: 'AR',
    name: 'Argentina'
  }
}