// material-ui
import { FormControl, Grid2 as Grid, TextField } from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import { ClientCreate } from '../../../../types/Client'
import { formatName } from '../../../../utils/formatter'

// ========================|| CLIENT - FORM ||======================== //

export default function ClientCreateForm() {
  const { t } = useTranslation()
  const { control } = useFormContext<ClientCreate>()

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('Name')}
                value={value}
                onChange={e => {
                  const value = formatName(e.target.value)
                  return onChange(value)
                }}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
