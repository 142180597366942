// project imports
import UserCompaniesContext from '../../../context/usercompany/UserCompaniesContext'
import useUserCompanies from '../../../hooks/usercompany/useUserCompanies'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import UserCompanyCard from './UserCompanyCard/UserCompanyCard'

// ========================|| USER COMPANY ||======================== //

export default function UserCompany() {
  const state = useUserCompanies()

  if (state.firstLoading) {
    return <Loader/>
  }

  if (state.error) {
    return <ApiErrorAlert error={state.error} />
  }

  return (
    <UserCompaniesContext.Provider value={{ ...state }}>
      <UserCompanyCard />
    </UserCompaniesContext.Provider>
  )
}