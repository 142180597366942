import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import NotificationApi from '../../api/notification/NotificationApi'
import { Notification } from '../../types/Notification'
import { ApiError } from '../../types/ApiError'

// apis
const notificationApi = new NotificationApi()

// ========================|| HOOK - NOTIFICATION ||======================== //

const useNotifications = () => {
  const { auth } = useAuth()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()

  const searchNotifications = () => {
    auth!.getIdToken().then(token => {
      notificationApi.findAllByUserId(token)
        .then(data => setNotifications(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(searchNotifications, [auth])

  return { loading, error, notifications, setNotifications }
}

export default useNotifications
