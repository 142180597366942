// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import ProfessionalBusinessHoursCard from '../ProfessionalBusinessHoursCard/ProfessionalBusinessHoursCard'
import ProfessionalAdjustedHoursCard from '../ProfessionalAdjustedHoursCard/ProfessionalAdjustedHoursCard'
import ProfessionalBusinessHoursProvider from '../../../../context/businesshour/ProfessionalBusinessHoursProvider'

// ========================|| PROFESSIONAL TABS - BUSINESS HOURS ||======================== //

export default function ProfessionalBusinessHoursTab() {
  return (
    <ProfessionalBusinessHoursProvider>
      <Grid container spacing={3}>
        <Grid size={12}>
          <ProfessionalBusinessHoursCard />
        </Grid>
        <Grid size={12}>
          <ProfessionalAdjustedHoursCard />
        </Grid>
      </Grid>
    </ProfessionalBusinessHoursProvider>
  )
}
