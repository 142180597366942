import React, { useContext, useState } from 'react'

// material-ui
import { Box, Divider, FormControl, Grid2 as Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import BillingApi from '../../../../api/billing/BillingApi'
import BillingContext from '../../../../context/billing/BillingContext'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import { formatCUIT, matchIsValidCUIT } from '../../../../utils/identifier'
import { BillingInformation } from '../../../../types/BillingInformation'
import { TaxCategory } from '../../../../types/TaxCategory'
import { getTaxIdentifierType, TaxIdentifierType } from '../../../../types/TaxIdentifierType'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// apis
const billingApi = new BillingApi()

// ========================|| BILLING - FORM ||======================== //

interface FormValues {
  name: string
  address: string
  taxIdentifier: string
  taxIdentifierType: TaxIdentifierType
  taxCategory: TaxCategory
}

export default function BillingInformationForm() {
  const companyId = useCompanyId()
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { billing, setBilling } = useContext(BillingContext)
  const dispatch = useDispatch()

  const { control, watch, formState, handleSubmit, reset, resetField, setValue, clearErrors } = useForm<FormValues>({
    defaultValues: {
      name: billing?.name || '',
      address: billing?.address || '',
      taxIdentifier: billing?.taxIdentifier || '',
      taxIdentifierType: billing?.taxIdentifierType || TaxIdentifierType.DNI,
      taxCategory: billing?.taxCategory || TaxCategory.CONSUMIDOR_FINAL,
    },
  })

  const isDNI = watch('taxIdentifierType') === TaxIdentifierType.DNI
  const [loading, setLoading] = useState(false)

  const handleSuccess = (billing: BillingInformation) => {
    setBilling(billing)
    reset({
      name: billing.name,
      address: billing.address,
      taxIdentifier: billing.taxIdentifier,
      taxIdentifierType: billing.taxIdentifierType,
      taxCategory: billing.taxCategory,
    })

    dispatch(
      setSnackbar({
        message: t('Billing information updated successfully') as string,
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating billing information'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      billingApi
        .update(token, companyId, {
          name: form.name,
          address: form.address,
          taxIdentifier: form.taxIdentifier,
          taxIdentifierType: form.taxIdentifierType,
          taxCategory: form.taxCategory,
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  return (
    <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Controller
            name='taxCategory'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <InputLabel id='tax-category-label' htmlFor='tax-category-select'>
                  {t('Tax category')}
                </InputLabel>
                <Select
                  labelId='tax-category-label'
                  label={t('Tax category')}
                  value={value}
                  onChange={event => {
                    if (event.target.value) {
                      const taxCategory = event.target.value as TaxCategory
                      setValue('taxIdentifierType', getTaxIdentifierType(taxCategory), { shouldDirty: true })
                    }

                    if (event.target.value === billing?.taxCategory) {
                      resetField('taxIdentifier')
                      return onChange(event)
                    }

                    setValue('taxIdentifier', '', { shouldDirty: true })
                    clearErrors('taxIdentifier')
                    return onChange(event)
                  }}
                  inputProps={{ id: 'tax-category-select' }}
                >
                  <MenuItem value={TaxCategory.CONSUMIDOR_FINAL}>Consumidor Final</MenuItem>
                  <MenuItem value={TaxCategory.MONOTRIBUTO}>Monotributo</MenuItem>
                  <MenuItem value={TaxCategory.RESPONSABLE_INSCRIPTO}>Responsable Inscripto</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Controller
            name='taxIdentifier'
            control={control}
            rules={{
              required: 'This field is required.',
              minLength: isDNI
                ? { value: 7, message: 'Document number must be between 7 and 8 characters.' }
                : { value: 11, message: 'CUIT number must have 11 characters.' },
              maxLength: isDNI
                ? { value: 8, message: 'Document number must be between 7 and 8 characters.' }
                : { value: 11, message: 'CUIT number must have 11 characters.' },
              validate: isDNI ? () => true : value => matchIsValidCUIT(value) || 'Invalid CUIT.',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={isDNI ? t('DNI') : t('CUIT')}
                fullWidth
                value={isDNI ? value : formatCUIT(value || '')}
                onChange={e => {
                  if (isDNI) {
                    // Delete any character that is not a number and limit to 8
                    const numericValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 8)
                    return onChange(numericValue)
                  } else {
                    // Only numbers
                    let numericValue = e.target.value.replace(/[^0-9]/g, '')

                    // Avoid "00"
                    if (numericValue.startsWith('00')) {
                      numericValue = numericValue.slice(1)
                    }

                    // Limit to 11 characters
                    numericValue = numericValue.slice(0, 11)

                    return onChange(numericValue)
                  }
                }}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Controller
            name='name'
            control={control}
            rules={{
              required: 'This field is required.',
              minLength: { value: 2, message: 'Name is too short.' },
              maxLength: { value: 255, message: 'Name is too long.' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='name'
                label={t('Name or company name')}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Controller
            name='address'
            control={control}
            rules={{
              required: 'This field is required.',
              minLength: { value: 2, message: 'Address is too short.' },
              maxLength: { value: 255, message: 'Address is too long.' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='address'
                label={t('Address')}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton variant='contained' type='submit' disabled={!formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
