// project imports
import { axiosInstance } from '../../axios'
import { Plan } from '../../types/Plan'

// ========================|| API - PLAN ||======================== //

export default class PlanApi {
  findByCountry(token: string, countryId: number): Promise<Plan> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/plans/${countryId}`, config)
  }
}
