// material-ui
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'

// project imports
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useProfessionalId from '../../../../hooks/professional/useProfessionalId'
import Tabs from '../../../../ui-components/extended/tabs/Tabs'
import { Tab } from '../../../../ui-components/extended/tabs/Tab'

// ========================|| PROFESSIONAL - TABS ||======================== //

export default function ProfessionalTabs() {
  const companyId = useCompanyId()
  const professionalId = useProfessionalId()
  const URL_PROFESSIONAL = `/companies/${companyId}/professionals/${professionalId}`
  const URL_PROFESSIONAL_BUSINESS_HOURS = `/companies/${companyId}/professionals/${professionalId}/business-hours`
  const URL_PROFESSIONAL_USERS = `/companies/${companyId}/professionals/${professionalId}/users`

  const tabs: Tab[] = [
    {
      url: URL_PROFESSIONAL,
      title: 'Details',
      icon: <AccountCircleTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_PROFESSIONAL_BUSINESS_HOURS,
      title: 'Business Hours',
      icon: <AccessTimeTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_PROFESSIONAL_USERS,
      title: 'Users',
      icon: <PersonOutlineTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
  ]

  return <Tabs tabs={tabs} />
}
