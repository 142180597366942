import { TaxCategory } from './TaxCategory'

export enum TaxIdentifierType {
  DNI = 'DNI',
  CUIT = 'CUIT',
}

export const getTaxIdentifierType = (taxCategory: TaxCategory) => {
  switch (taxCategory) {
    case TaxCategory.CONSUMIDOR_FINAL:
      return TaxIdentifierType.DNI
    case TaxCategory.MONOTRIBUTO:
      return TaxIdentifierType.CUIT
    case TaxCategory.RESPONSABLE_INSCRIPTO:
      return TaxIdentifierType.CUIT
    default:
      throw new Error(`No tax identifier type found for tax category: ${taxCategory}`)
  }
}
