// project imports
import { axiosInstance } from '../../axios'
import { Branch, BranchParams } from '../../types/Branch'
import { BranchUpsertDto } from './dto/BranchUpsertDto'

// ========================|| API - COMPANY BRANCH ||======================== //

export default class CompanyBranchApi {
  findAll(token: string, companyId: string, params?: BranchParams): Promise<Branch[]> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/branches`, config)
  }

  create(token: string, companyId: string, branch: BranchUpsertDto): Promise<Branch> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/branches`, branch, config)
  }
}
