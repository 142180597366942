import React, { Ref } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Card, CardContent, CardContentProps, CardHeader, CardHeaderProps, CardProps, Divider } from '@mui/material'

// constant
const headerActionStyle = {
  '& .MuiCardHeader-action': { mr: 0 },
}

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps {
  border?: boolean
  boxShadow?: boolean
  children: React.ReactNode | string
  style?: React.CSSProperties
  content?: boolean
  className?: string
  headerSX?: CardContentProps['sx']
  contentSX?: CardContentProps['sx']
  sx?: CardProps['sx']
  secondary?: CardHeaderProps['action']
  shadow?: string
  elevation?: number
  title?: React.ReactNode
}

const MainCard = React.forwardRef(
  (
    { border = true, boxShadow, children, content = true, headerSX, contentSX, secondary, shadow, sx = {}, title, ...others }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme()

    return (
      <Card
        ref={ref}
        {...others}
        elevation={0}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.divider,
          ':hover': {
            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit',
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {title && (
          <CardHeader
            sx={{ padding: '24px', ...headerActionStyle, ...headerSX }}
            titleTypographyProps={{ fontSize: '1.125rem' }}
            title={title}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && <Divider sx={{ borderCollapse: 'collapse' }} />}

        {/* card content */}
        {content && <CardContent sx={{ padding: '24px', ...contentSX }}>{children}</CardContent>}
        {!content && children}
      </Card>
    )
  },
)

export default MainCard
