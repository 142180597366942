import React, { useContext } from 'react'

// material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// project imports
import PaymentContext from '../../../../context/payment/PaymentContext'
import PaymentStatusChip from '../../../../ui-components/extended/chip/PaymentStatusChip'
import MainTablePagination from '../../../../ui-components/extended/table/MainTablePagination'
import InvoiceDownloadButton from '../InvoiceDownloadButton/InvoiceDownloadButton'
import { locale } from '../../../../utils/locale'
import { Payment } from '../../../../types/Payment'
import { getPriceString } from '../../../../utils/typography'

// ========================|| BILLING - TABLE ||======================== //

export default function BillingHistoryTable() {
  const theme = useTheme()
  const { i18n, t } = useTranslation()
  const { payments, pageable, setPageable } = useContext(PaymentContext)

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            '.MuiTableCell-root': {
              '&:first-of-type': {
                padding: '16px 16px 16px 20px',
              },
              '&:last-child': {
                padding: '16px 20px 20px 16px',
              },
              borderColor: theme.palette.divider,
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('ID')}</TableCell>
              <TableCell>{t('Date')}</TableCell>
              <TableCell align='right'>{t('Price')}</TableCell>
              <TableCell align='center'>{t('Status')}</TableCell>
              <TableCell align='center' width={140}>{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.content.map((payment: Payment) => (
              <TableRow key={payment.id}>
                <TableCell>{payment.id}</TableCell>
                <TableCell>{dayjs(payment.debitDate).locale(locale(i18n.language)).format('DD MMM YYYY, HH:mm')}</TableCell>
                <TableCell align='right'>{getPriceString(payment.amount, payment.currency, i18n.language)}</TableCell>
                <TableCell align='center'>
                  <PaymentStatusChip status={payment.status} size='small' />
                </TableCell>
                <TableCell align='center'>
                  <InvoiceDownloadButton payment={payment} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MainTablePagination
        count={payments.page.totalElements}
        pageable={pageable}
        setPageable={setPageable}
        sx={{ '.MuiTablePagination-toolbar': { p: 0 } }}
      />
    </>
  )
}
