import { ReactNode } from 'react'

// project imports
import NotificationContext from './NotificationContext'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'
import useNotifications from '../../hooks/notification/useNotifications'

// ========================|| PROVIDER - NOTIFICATION ||======================== //

export default function NotificationProvider({ children }: { children: ReactNode }): JSX.Element {
  const { error, loading, notifications, setNotifications } = useNotifications()

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  return <NotificationContext.Provider value={{ notifications, setNotifications }}>{children}</NotificationContext.Provider>
}
