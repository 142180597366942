// material-ui
import { TextField } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import ServiceProfessionalAutocomplete from '../../../../../ui-components/extended/autocomplete/ServiceProfessionalAutocomplete/ServiceProfessionalAutocomplete'
import { BookingCreateForm } from '../../../../../types/Booking'

// ========================|| BOOKING - FORM - SELECT PROFESSIONAL STEP ||======================== //

export default function SelectProfessionalStep() {
  const { t } = useTranslation()
  const { control, watch, resetField } = useFormContext<BookingCreateForm>()
  const service = watch('service')

  return (
    <Controller
      name='professional'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ServiceProfessionalAutocomplete
          service={service}
          value={value}
          onChange={(e, professional) => {
            resetField('date')
            resetField('dateTime')
            return onChange(professional)
          }}
          renderInput={params => (
            <TextField {...params} label={t('Professional')} error={!!error} helperText={error ? t(error.message as string) : null} />
          )}
        />
      )}
    />
  )
}
