// material-ui
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'

// project imports
import useCompanyId from '../../../../hooks/company/useCompanyId'
import useBranchId from '../../../../hooks/branch/useBranchId'
import Tabs from '../../../../ui-components/extended/tabs/Tabs'
import { Tab } from '../../../../ui-components/extended/tabs/Tab'

// ========================|| BRANCH - TABS ||======================== //

export default function BranchTabs() {
  // hooks
  const companyId = useCompanyId()
  const branchId = useBranchId()

  const URL_BRANCH = `/companies/${companyId}/branches/${branchId}`
  const URL_BRANCH_USERS = `/companies/${companyId}/branches/${branchId}/users`

  const tabs: Tab[] = [
    {
      url: URL_BRANCH,
      title: 'Details',
      icon: <AccountCircleTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_BRANCH_USERS,
      title: 'Users',
      icon: <PersonOutlineTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
  ]

  return <Tabs tabs={tabs} />
}
