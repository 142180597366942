// project imports
import { axiosInstance } from '../../axios'
import { BillingInformation } from '../../types/BillingInformation'
import { BillingInformationUpdateDto } from './dto/BillingInformationUpdateDto'

// ========================|| API - BILLING ||======================== //

export default class BillingApi {
  findByCompanyId(token: string, companyId: string): Promise<BillingInformation> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.get(`/api/v1/companies/${companyId}/billing`, config)
  }

  update(token: string, companyId: string, billing: BillingInformationUpdateDto): Promise<BillingInformation> {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    return axiosInstance.post(`/api/v1/companies/${companyId}/billing`, billing, config)
  }
}
