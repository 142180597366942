import { ReactNode } from 'react'

// project imports
import SelectServiceStep from '../CompanyBookingStepper/SelectServiceStep/SelectServiceStep'
import SelectBranchStep from '../CompanyBookingStepper/SelectBranchStep/SelectBranchStep'
import SelectProfessionalStep from '../CompanyBookingStepper/SelectProfessionalStep/SelectProfessionalStep'
import SelectDateStep from '../CompanyBookingStepper/SelectDateStep/SelectDateStep'
import CheckoutStep from '../CompanyBookingStepper/CheckoutStep/CheckoutStep'

// ========================|| STEPPER ITEMS ||======================== //

export type BookingField = 'branch' | 'service' | 'professional' | 'date' | 'dateTime'

export interface StepperItem {
  id: string
  fields: string[]
  title: string
  isStepValid?: (trigger: any) => Promise<boolean>
  component: ReactNode
}

const stepperItems: StepperItem[] = [
  {
    id: 'service',
    fields: ['service'],
    title: 'Service',
    component: <SelectServiceStep />,
  },
  {
    id: 'branch',
    fields: ['branch'],
    title: 'Branch',
    component: <SelectBranchStep />,
  },
  {
    id: 'professional',
    fields: ['professional'],
    title: 'Professional',
    component: <SelectProfessionalStep />,
  },
  {
    id: 'date',
    fields: ['date', 'dateTime'],
    title: 'Date',
    component: <SelectDateStep />,
  },
  {
    id: 'checkout',
    fields: ['customer.name', 'customer.phone'],
    title: 'Checkout',
    component: <CheckoutStep />,
  },
]

export default stepperItems
