import React, { useContext, useState } from 'react'

// material-ui
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  InputAdornment,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

// project imports
import SettingsContext from '../../../../context/settings/SettingsContext'
import IntegrationContext from '../../../../context/integration/IntegrationContext'
import GreenSwitch from '../../../../ui-components/extended/switch/GreenSwitch'
import CompanySettingsApi from '../../../../api/settings/CompanySettingsApi'
import useAuth from '../../../../context/auth/useAuth'
import useCompanyId from '../../../../hooks/company/useCompanyId'
import IntegrationMercadoPagoNotEnabledAlert from '../../../../ui-components/extended/alert/IntegrationMercadoPagoNotEnabledAlert'
import { formatNumber } from '../../../../utils/formatter'
import { Settings } from '../../../../types/Settings'
import { setSnackbar } from '../../../../store/snackbar/reducer'

// apis
const settingsApi = new CompanySettingsApi()

// ========================|| SETTINGS - FORM ||======================== //

interface FormValues {
  confirmationEnabled: boolean
  refundEnabled: boolean
  depositPercentage: string
  minCancellationNotice: string
  minSchedulingNotice: string
  maxSchedulingNotice: string
}

export default function SettingsForm() {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { settings, setSettings } = useContext(SettingsContext)
  const { integration } = useContext(IntegrationContext)
  const companyId = useCompanyId()
  const dispatch = useDispatch()

  // react-hook-form
  const { control, watch, formState, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      confirmationEnabled: settings.confirmationEnabled,
      refundEnabled: settings.refundEnabled,
      depositPercentage: String(settings.depositPercentage * 100),
      minCancellationNotice: String(settings.minCancellationNotice),
      minSchedulingNotice: String(settings.minSchedulingNotice),
      maxSchedulingNotice: String(settings.maxSchedulingNotice),
    },
  })

  // state
  const [loading, setLoading] = useState(false)
  const depositPercentage: number = Number(watch('depositPercentage'))
  const refundEnabled: boolean = watch('refundEnabled')
  const hasMercadoPagoEnabled = integration.mercadoPago

  const handleSuccess = (settings: Settings) => {
    setSettings(settings)

    reset({
      confirmationEnabled: settings.confirmationEnabled,
      refundEnabled: settings.refundEnabled,
      depositPercentage: String(settings.depositPercentage * 100),
      minCancellationNotice: String(settings.minCancellationNotice),
      minSchedulingNotice: String(settings.minSchedulingNotice),
      maxSchedulingNotice: String(settings.maxSchedulingNotice),
    })

    dispatch(
      setSnackbar({
        message: t('Settings updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating settings'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      settingsApi
        .update(token, companyId, {
          confirmationEnabled: form.confirmationEnabled,
          refundEnabled: form.refundEnabled,
          depositPercentage: Number(form.depositPercentage) / 100,
          minCancellationNotice: Number(form.minCancellationNotice),
          minSchedulingNotice: Number(form.minSchedulingNotice),
          maxSchedulingNotice: Number(form.maxSchedulingNotice),
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  const isValidSchedulingNotice = () => {
    const minSchedulingNotice = Number(watch('minSchedulingNotice'))
    const maxSchedulingNotice = Number(watch('maxSchedulingNotice')) * 24

    if (minSchedulingNotice >= maxSchedulingNotice) {
      return 'The minimum scheduling notice must be less the maximum scheduling notice.'
    }

    return true
  }

  return (
    <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Controller
            name='depositPercentage'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component='fieldset' variant='standard' fullWidth>
                <FormLabel component='legend'>
                  <Typography variant='subtitle1' gutterBottom>
                    {t('Deposit percentage')}
                  </Typography>
                </FormLabel>
                <Grid container spacing={1} display='flex' alignItems='center'>
                  <Grid>
                    <Typography mr={2}>0%</Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center' size='grow'>
                    <Slider
                      aria-label='Deposit Percentage'
                      value={Number(value)}
                      onChange={(event, value) => onChange(value.toString())}
                      getAriaValueText={value => `${value * 100}%`}
                      valueLabelDisplay='auto'
                      shiftStep={5}
                      step={5}
                      min={0}
                      max={100}
                    />
                  </Grid>
                  <Grid>
                    <Typography ml={2}>100%</Typography>
                  </Grid>
                </Grid>
                <FormHelperText>{t('Determines the percentage of the service price that the client must pay when making a booking.')}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        {!hasMercadoPagoEnabled && depositPercentage > 0 && (
          <Grid size={12}>
            <IntegrationMercadoPagoNotEnabledAlert />
          </Grid>
        )}
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <Controller
            name='refundEnabled'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component='fieldset' variant='standard' fullWidth>
                <FormLabel component='legend'>
                  <Typography variant='subtitle1'>{t('Refund policy')}</Typography>
                </FormLabel>
                <FormControlLabel
                  value={value}
                  checked={value}
                  onChange={onChange}
                  control={<GreenSwitch id='refundEnabled' />}
                  label={t('Refund deposit on cancel')}
                />
                <FormHelperText>{t('Refund deposit automatically when the client cancels with certain prior notice.')}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        {refundEnabled && (
          <Grid size={12}>
            <Controller
              name='minCancellationNotice'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 0, message: 'The minimum cancellation notice must be greater than or equal to 0 hours.' },
                max: { value: 240, message: 'The minimum cancellation notice must be less than or equal to 240 hours.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('Minimum cancellation notice')}
                  value={value}
                  error={!!error}
                  onChange={e => {
                    const value = formatNumber(e.target.value, 3)
                    return onChange(value)
                  }}
                  fullWidth
                  slotProps={{ input: { endAdornment: <InputAdornment position='end'>{t('hours')}</InputAdornment> } }}
                  helperText={error ? t(error.message as string) : t('booking_anticipationHelperText')}
                />
              )}
            />
          </Grid>
        )}
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <Controller
            name='confirmationEnabled'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component='fieldset' variant='standard' fullWidth>
                <FormLabel component='legend'>
                  <Typography variant='subtitle1'>{t('Booking confirmation')}</Typography>
                </FormLabel>
                <FormControlLabel
                  control={<GreenSwitch id='confirmationEnabled' value={value} checked={value} onChange={onChange} />}
                  label={t('Confirm bookings manually')}
                />
                <FormHelperText>
                  {t('Applies only to bookings without deposit, either because the price is not defined or because the service deposit percentage is 0%.')}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12} container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='minSchedulingNotice'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 0, message: 'The minimum scheduling notice must be greater than or equal to 0 hours.' },
                max: { value: 240, message: 'The minimum scheduling notice must be less than or equal to 240 hours.' },
                validate: { isValidSchedulingNotice }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('Minimum scheduling notice')}
                  value={value}
                  error={!!error}
                  onChange={e => {
                    const value = formatNumber(e.target.value, 3)
                    return onChange(value)
                  }}
                  fullWidth
                  slotProps={{ input: { endAdornment: <InputAdornment position='end'>{t('hours')}</InputAdornment> } }}
                  helperText={error ? t(error.message as string) : t('The minimum amount of time required for a customer to make a booking in advance.')}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Controller
              name='maxSchedulingNotice'
              control={control}
              rules={{
                required: 'This field is required.',
                min: { value: 1, message: 'The maximum scheduling notice must be greater than or equal to 1 day.' },
                max: { value: 365, message: 'The maximum scheduling notice must be less than or equal to 365 days.' },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('Maximum scheduling notice')}
                  value={value}
                  error={!!error}
                  onChange={e => {
                    const value = formatNumber(e.target.value, 3)
                    return onChange(value)
                  }}
                  fullWidth
                  slotProps={{ input: { endAdornment: <InputAdornment position='end'>{t('days')}</InputAdornment> } }}
                  helperText={error ? t(error.message as string) : t('The maximum amount of time required for a customer to make a booking in advance.')}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
