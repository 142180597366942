import { useContext, useState } from 'react'

// material-ui
import { Button, Tooltip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import BranchUserCreateDialog from '../BranchUserCreateDialog/BranchUserCreateDialog'
import { isInactive } from '../../../../utils/subscription'

// ========================|| BRANCH USER - CREATE BUTTON ||======================== //

export default function BranchUserCreateButton() {
  // hooks
  const { t } = useTranslation()
  const { subscription } = useContext(OptionalSubscriptionContext)

  // state
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={isInactive(subscription) ? t('An active subscription is required to add users.') : null}>
        <span>
          <Button size='small' disabled={isInactive(subscription)} onClick={handleOpen}>
            {t('Add')}
          </Button>
        </span>
      </Tooltip>
      <BranchUserCreateDialog open={open} onClose={handleClose} />
    </>
  )
}
