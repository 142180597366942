import { useContext, useEffect } from 'react'

// project imports
import OptionalSubscriptionContext from '../../../context/subscription/OptionalSubscriptionContext'
import DiscountProvider from '../../../context/discount/DiscountProvider'
import SubscriptionProvider from '../../../context/subscription/SubscriptionProvider'
import PlanBillingDiscountProvider from '../../../context/plan/PlanBillingDiscountProvider'
import BillingDiscountProvider from '../../../context/discount/BillingDiscountProvider'
import PlanProvider from '../../../context/plan/PlanProvider'
import useCompanyId from '../../../hooks/company/useCompanyId'
import useSubscription from '../../../hooks/subscription/useSubscription'
import Loader from '../../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../../ui-components/extended/alert/ApiErrorAlert'
import SubscriptionCard from './SubscriptionCard/SubscriptionCard'
import SubscriptionCreateCard from './SubscriptionCreateCard/SubscriptionCreateCard'
import SubscriptionFreeTrialCard from './SubscriptionFreeTrialCard/SubscriptionFreeTrialCard'
import { SubscriptionStatus } from '../../../types/Subscription'

// ========================|| SUBSCRIPTION ||======================== //

export default function Subscription() {
  const companyId = useCompanyId()
  const { subscription, setSubscription } = useContext(OptionalSubscriptionContext)
  const { loading, error, subscription: updated } = useSubscription(companyId)

  useEffect(() => {
    if (!loading && !error) {
      setSubscription(updated)
    }
  }, [updated])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ApiErrorAlert error={error} />
  }

  if (!subscription) {
    return (
      <PlanBillingDiscountProvider>
        <SubscriptionCreateCard />
      </PlanBillingDiscountProvider>
    )
  }

  if (subscription.status === SubscriptionStatus.TRIALING) {
    return (
      <SubscriptionProvider subscription={subscription}>
        <PlanProvider plan={subscription.plan}>
          <BillingDiscountProvider>
            <SubscriptionFreeTrialCard />
          </BillingDiscountProvider>
        </PlanProvider>
      </SubscriptionProvider>
    )
  }

  return (
    <SubscriptionProvider subscription={subscription}>
      <DiscountProvider>
        <SubscriptionCard />
      </DiscountProvider>
    </SubscriptionProvider>
  )
}
