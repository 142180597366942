import { ReactNode } from 'react'

// project imports
import BillingContext from '../billing/BillingContext'
import DiscountContext from '../discount/DiscountContext'
import useCompanyId from '../../hooks/company/useCompanyId'
import useBilling from '../../hooks/billing/useBilling'
import useDiscounts from '../../hooks/discount/useDiscounts'
import NotFound from '../../views/Maintenance/NotFound/NotFound'
import Loader from '../../ui-components/extended/progress/Loader/Loader'
import ApiErrorAlert from '../../ui-components/extended/alert/ApiErrorAlert'

// ========================|| PROVIDER - BILLING & DISCOUNT ||======================== //

export default function BillingDiscountProvider({ children }: { children: ReactNode }): JSX.Element {
  const companyId = useCompanyId()
  const { loading: loadingBilling, error: errorBilling, billing, setBilling } = useBilling(companyId)
  const { loading: loadingDiscounts, error: errorDiscounts, discounts, setDiscounts } = useDiscounts(companyId)

  const loading = loadingBilling || loadingDiscounts

  if (!companyId) {
    return <NotFound />
  }

  if (loading) {
    return <Loader />
  }

  if (errorBilling && errorBilling.status !== 404) {
    return <ApiErrorAlert error={errorBilling} />
  }

  if (errorDiscounts) {
    return <ApiErrorAlert error={errorDiscounts} />
  }

  return (
    <BillingContext.Provider value={{ billing, setBilling }}>
      <DiscountContext.Provider value={{ discounts, setDiscounts }}>
        {children}
      </DiscountContext.Provider>
    </BillingContext.Provider>
  )
}
