import { useContext } from 'react'

// material-ui
import { TextField, useMediaQuery, useTheme } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

// project imports
import CompanyContext from '../../../../../context/company/CompanyContext'
import ServiceAutocomplete from '../../../../../ui-components/extended/autocomplete/ServiceAutocomplete/ServiceAutocomplete'

// ========================|| BOOKING FORM - SELECT SERVICE ||======================== //

export default function SelectServiceStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const { company } = useContext(CompanyContext)
  const { control, resetField } = useFormContext()

  return (
    <Controller
      name='service'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ServiceAutocomplete
          companyId={company.id}
          value={value}
          onChange={(e, service) => {
            resetField('professional')
            resetField('date')
            resetField('dateTime')
            return onChange(service)
          }}
          renderInput={params =>
            matchesXS ? (
              <TextField
                {...params}
                placeholder={t('Service')}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
              />
            ) : (
              <TextField
                {...params}
                label={t('Service')}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
              />
            )
          }
        />
      )}
    />
  )
}
