import { useContext, useState } from 'react'

// material-ui
import { Box, Divider, Grid2 as Grid, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

// project imports
import ProfessionalAdjustedHoursApi from '../../../../api/adjustedhour/ProfessionalAdjustedHoursApi'
import ProfessionalContext from '../../../../context/professional/ProfessionalContext'
import AdjustedHoursContext from '../../../../context/businesshour/AdjustedHoursContext'
import useAuth from '../../../../context/auth/useAuth'
import useProfessionalId from '../../../../hooks/professional/useProfessionalId'
import AdjustedHoursFormControl from '../../BusinessHour/AdjustedHoursFormControl/AdjustedHoursFormControl'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { AdjustedHour } from '../../../../types/BusinessHour'

// apis
const adjustedHoursApi = new ProfessionalAdjustedHoursApi()

// ========================|| BRANCH - ADJUSTED HOURS - FORM ||======================== //

interface FormValues {
  adjustedHours: AdjustedHour[]
}

export default function ProfessionalAdjustedHoursForm() {
  const theme = useTheme()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { professional } = useContext(ProfessionalContext)
  const { adjustedHours, setAdjustedHours } = useContext(AdjustedHoursContext)
  const professionalId = useProfessionalId()
  const dispatch = useDispatch()

  // react-hook-form
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      adjustedHours: adjustedHours,
    },
  })

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (adjustedHours: AdjustedHour[]) => {
    setAdjustedHours(adjustedHours)

    methods.reset({ adjustedHours })

    dispatch(
      setSnackbar({
        message: t('Adjusted availability updated successfully') as string,
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = () => {
    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating adjusted availability'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    setLoading(true)
    auth!.getIdToken().then(token => {
      adjustedHoursApi
        .update(token, professionalId, form)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setLoading(false)
        })
    })
  }

  return (
    <Box component='form' onSubmit={methods.handleSubmit(handleSubmitForm)} sx={{ [theme.breakpoints.only('xs')]: { width: 'max-content' } }}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <FormProvider {...methods}>
            <AdjustedHoursFormControl timezone={professional.branch.address.timezone} />
          </FormProvider>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton variant='contained' type='submit' disabled={!methods.formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
