// Company
export const EXCEPTION_COMPANY_NAME_ALREADY_EXIST = 'Company name already exists.'
export const EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD = 'Company name is a reserved keyword.'

// Branch
export const EXCEPTION_BRANCH_NAME_ALREADY_EXIST = 'Branch name already exists.'
export const EXCEPTION_BRANCH_ADDRESS_ALREADY_EXIST = 'Branch address already exists.'

// Professional
export const EXCEPTION_PROFESSIONAL_LIMIT_REACHED = 'Professional limit reached. Please upgrade your subscription to create more professionals.'
export const EXCEPTION_PROFESSIONAL_NAME_ALREADY_EXIST = 'Professional name already exists.'

// Service
export const EXCEPTION_SERVICE_NAME_ALREADY_EXIST = 'Service name already exists.'

// User
export const EXCEPTION_USER_NOT_FOUND = 'User not found.'
export const EXCEPTION_USER_COMPANY_ALREADY_EXIST = 'User company already exists.'
export const EXCEPTION_USER_BRANCH_ALREADY_EXIST = 'User branch already exists.'
export const EXCEPTION_USER_PROFESSIONAL_ALREADY_EXIST = 'User professional already exists.'

export const EXCEPTION_USER_COMPANY_LIMIT_REACHED = 'Companies limit reached.'
export const EXCEPTION_USER_BRANCH_LIMIT_REACHED = 'Branches limit reached.'
export const EXCEPTION_USER_PROFESSIONAL_LIMIT_REACHED = 'Professionals limit reached.'

// Subscription
export const EXCEPTION_SUBSCRIPTION_DOWNGRADE = 'You have more professionals than this subscription allows. Please remove professionals to proceed.'
export const EXCEPTION_SUBSCRIPTION_REQUIRED = 'An active subscription is required to perform this action'

// Booking
export const EXCEPTION_BOOKING_NOT_CANCELABLE = 'The booking is not cancelable'
export const EXCEPTION_BOOKING_NOT_AVAILABLE = 'An error occurred creating the booking. The provided date is not available.'

// Coupon
export const EXCEPTION_DISCOUNT_CODE_NOT_FOUND = 'Discount coupon not found.'
export const EXCEPTION_DISCOUNT_CODE_ALREADY_EXISTS = 'The discount coupon has already been applied to the company.'
export const EXCEPTION_DISCOUNT_CODE_LIMIT_REACHED = 'The discount coupon has reached its usage limit.'




