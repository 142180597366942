import { useEffect } from 'react'

// material-ui
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone'

// third-party
import { useDispatch } from 'react-redux'

// project imports
import Tabs from '../../../ui-components/extended/tabs/Tabs'
import { Tab } from '../../../ui-components/extended/tabs/Tab'
import { setIsOpen } from '../../../store/menu/reducer'

// ========================|| USER - TABS ||======================== //

export default function UserTabs() {
  // hooks
  const dispatch = useDispatch()

  const URL_PROFILE = '/users/me'
  const URL_NOTIFICATIONS = '/users/me/notifications'

  useEffect(() => {
    dispatch(setIsOpen('/users/me'))
  }, [])

  const tabs: Tab[] = [
    {
      url: URL_PROFILE,
      title: 'Profile',
      icon: <AccountCircleTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
    {
      url: URL_NOTIFICATIONS,
      title: 'Notifications',
      icon: <NotificationsNoneTwoToneIcon fontSize='small' sx={{ marginRight: '10px' }} />,
      active: true,
    },
  ]

  return <Tabs tabs={tabs} />
}
