// third-party
import { useTranslation } from 'react-i18next'

// project imports
import SubCard from '../../../../ui-components/extended/card/SubCard'
import ProfessionalAdjustedHoursForm from '../ProfessionalAdjustedHoursForm/ProfessionalAdjustedHoursForm'

// ========================|| PROFESSIONAL - ADJUSTED HOURS - CARD ||======================== //

export default function ProfessionalAdjustedHoursCard() {
  const { t } = useTranslation()

  return (
    <SubCard title={t('Adjusted availability')} contentSX={{ overflowX: 'scroll' }}>
      <ProfessionalAdjustedHoursForm />
    </SubCard>
  )
}
