import React, { useContext } from 'react'

// material-ui
import { TextField, useMediaQuery, useTheme } from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import CompanyContext from '../../../../../context/company/CompanyContext'
import BranchAutocomplete from '../../../../../ui-components/extended/autocomplete/BranchAutocomplete/BranchAutocomplete'

// ========================|| BOOKING FORM - SELECT BRANCH ||======================== //

export default function SelectBranchStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const { company } = useContext(CompanyContext)
  const { control, resetField } = useFormContext()

  return (
    <Controller
      name='branch'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BranchAutocomplete
          companyId={company.id}
          value={value}
          onChange={(e, branch) => {
            resetField('professional')
            resetField('date')
            resetField('dateTime')
            return onChange(branch)
          }}
          renderInput={params =>
            matchesXS ? (
              <TextField {...params} placeholder={t('Branch')} error={!!error} helperText={error ? t(error.message as string) : null} />
            ) : (
              <TextField {...params} label={t('Branch')} error={!!error} helperText={error ? t(error.message as string) : null} />
            )
          }
        />
      )}
    />
  )
}
