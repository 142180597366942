import { useContext } from 'react'

// third-party
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// project imports
import UserCompanyContext from '../../context/usercompany/UserCompanyContext'

// ========================|| HOOK - COMPANY BREADCRUMBS ||======================== //

const useBreadcrumbs = () => {
  const { userCompany } = useContext(UserCompanyContext)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const companiesUrl = { title: t('Companies'), url: '/users/me/companies' }
  const company = { title: userCompany.displayName }
  const companyUrl = { title: userCompany.displayName, url: `/companies/${userCompany.id}` }
  const bookingsUrl = { title: t('Bookings'), url: `/companies/${userCompany.id}/bookings` }

  const bookings = { title: t('Bookings') }
  const bookingNew = { title: t('New') }
  const clients = { title: t('Clients') }
  const businessHours = { title: t('Business Hours') }
  const settings = { title: t('Settings') }
  const billing = { title: t('Billing') }
  const integrations = { title: t('Integrations') }
  const subscription = { title: t('Subscription') }
  const branches = { title: t('Branches') }
  const professionals = { title: t('Professionals') }
  const services = { title: t('Services') }
  const users = { title: t('Users') }

  switch (pathname.split('/').at(-1)) {
    case 'bookings': {
      return [companiesUrl, companyUrl, bookings]
    }
    case 'new': {
      return [bookingsUrl, bookingNew]
    }
    case 'clients': {
      return [companiesUrl, companyUrl, clients]
    }
    case 'business-hours': {
      return [companiesUrl, companyUrl, businessHours]
    }
    case 'settings': {
      return [companiesUrl, companyUrl, settings]
    }
    case 'billing': {
      return [companiesUrl, companyUrl, billing]
    }
    case 'subscription': {
      return [companiesUrl, companyUrl, subscription]
    }
    case 'integrations': {
      return [companiesUrl, companyUrl, integrations]
    }
    case 'branches': {
      return [companiesUrl, companyUrl, branches]
    }
    case 'professionals': {
      return [companiesUrl, companyUrl, professionals]
    }
    case 'services': {
      return [companiesUrl, companyUrl, services]
    }
    case 'users': {
      return [companiesUrl, companyUrl, users]
    }
    default: {
      return [companiesUrl, company]
    }
  }
}

export default useBreadcrumbs
