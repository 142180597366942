// material-ui
import { Grid2 as Grid, ListItemText, Typography } from '@mui/material'

// third-party
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

// project imports
import SubCard from '../../../../../ui-components/extended/card/SubCard'
import { locale } from '../../../../../utils/locale'
import { useFormContext } from 'react-hook-form'
import { BookingCreateForm } from '../../../../../types/Booking'

// ========================|| BOOKING - FORM - CHECKOUT STEP ||======================== //

export default function SummaryStep() {
  const { i18n, t } = useTranslation()
  const { watch } = useFormContext<BookingCreateForm>()

  const client = watch('client')!
  const service = watch('service')!
  const professional = watch('professional')!
  const branch = professional.branch
  const dateTime = watch('dateTime')!

  return (
    <SubCard title={t('Booking Summary')}>
      <Grid container spacing={3}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Client')}
              </Typography>
              <Typography component='p' variant='body1'>
                {client.name}
              </Typography>
            </ListItemText>
          </Grid>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Branch')}
              </Typography>
              <Typography component='p' variant='body1'>
                {branch.address.description}
              </Typography>
            </ListItemText>
          </Grid>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Service')}
              </Typography>
              <Typography component='p' variant='body1'>
                {service.name} ({service.duration} {t('minutes')})
              </Typography>
            </ListItemText>
          </Grid>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Professional')}
              </Typography>
              <Typography component='p' variant='body1'>
                {professional.name}
              </Typography>
            </ListItemText>
          </Grid>
          <Grid size={12}>
            <ListItemText>
              <Typography component='span' variant='subtitle1'>
                {t('Date')}
              </Typography>
              <Typography component='p' variant='body1'>
                {capitalize(dayjs(dateTime).locale(locale(i18n.language)).calendar())}
              </Typography>
            </ListItemText>
          </Grid>
        </Grid>
      </Grid>
    </SubCard>
  )
}
