// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import PaymentMethodCard from '../PaymentMethodCard/PaymentMethodCard'
import SubscriptionUpdateCard from '../SubscriptionUpdateCard/SubscriptionUpdateCard'

// ========================|| SUBSCRIPTION - CARD ||======================== //

export default function SubscriptionCard() {
  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <SubscriptionUpdateCard />
      </Grid>
      <Grid size={12}>
        <PaymentMethodCard />
      </Grid>
    </Grid>
  )
}
