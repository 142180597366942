// third-party
import { Dayjs } from 'dayjs'

// project imports
import { Company } from './Company'
import { Branch } from './Branch'
import { Professional } from './Professional'
import { Service, ServiceDetails } from './Service'
import { Pageable, Searchable } from './Page'
import { Client } from './Client'
import { ServiceProfessional } from './ServiceProfessional'

// ========================|| TYPES - BOOKING ||======================== //

export enum BookingStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
}

export enum DepositStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  IN_MEDIATION = 'IN_MEDIATION',
  CHARGED_BACK = 'CHARGED_BACK',
}

export interface Booking {
  id: string
  date: Date
  duration: number
  price: number | null
  currency: string | null
  status: BookingStatus
  depositAmount: number
  depositStatus: DepositStatus
  checkoutUrl: string | null
  refundDeadline: Date | null
  company: Company
  branch: Branch
  professional: Professional
  service: Service
  client: Client
  createdAt: Date
}

export interface BookingParams extends Pageable, Searchable {
  startDate?: Dayjs
  endDate?: Dayjs
  status: BookingStatus[]
  professionals: Professional[]
  branchId?: string
}

export interface BookingCreateForm {
  branch: Branch | null
  service: ServiceDetails | null
  professional: ServiceProfessional | null
  client: Client | null
  date: Dayjs | null
  dateTime: string | null
}