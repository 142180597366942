// material-ui
import { FormControl, FormControlLabel, FormHelperText, Grid2 as Grid, Switch, TextField } from '@mui/material'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import { Client, ClientUpdate } from '../../../../types/Client'
import { formatName } from '../../../../utils/formatter'

// ========================|| CLIENT - EDIT FORM ||======================== //

interface Props {
  client: Client
}

export default function ClientEditForm({ client }: Props) {
  const { t } = useTranslation()
  const { control } = useFormContext<ClientUpdate>()

  return (
    <Grid container display='flex' spacing={3} pt='8px'>
      <Grid size={12}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: 'This field is required.',
            minLength: { value: 2, message: 'Name is too short.' },
            maxLength: { value: 100, message: 'Name is too long.' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                id='name'
                label={t('Name')}
                value={value}
                onChange={e => {
                  const value = formatName(e.target.value)
                  return onChange(value)
                }}
                error={!!error}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name='depositEnabled'
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl component='fieldset' variant='standard' fullWidth onChange={onChange} disabled={!client.user}>
              <FormControlLabel
                control={<Switch id='depositEnabled' value={value} checked={value} />}
                label={t('Require deposit')}
              />
              <FormHelperText>
                {client.user ? t('Enable or disable deposits for this client.') : t('Deposits can only be required from clients with a linked user.')}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  )
}
